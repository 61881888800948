import { BoardStatus } from './board-status.model';

export interface StatusGroupCreateRequest {
  statusIds?: number[];
  name: string;
  colorCode?: string;
  icon?: string;
  projectId: number
}

export interface StatusGroupDeleteRequest {
  name: string;
  colorCode: string;
  statusIds: number[];
}

export interface StatusGroup {
  id?: number
  code: string;
  name: string;
  colorCode: string;
  icon: string;
  deleted: true;
  defaultFlag: true;
  orderStr: string;
  boardStatusesInProject: BoardStatus[];
  dataType: StatusGroupDataType;
}

export interface StatusGroupUpsertRequest {
  name: string;
  colorCode?: string;
  statusIds?: number[];
  dataType?: StatusGroupDataType;
}

export enum StatusGroupDataType {
  DISCRETE = 1,
  CONTINUES = 2,
}
