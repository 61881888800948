import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'function'
})

export class FunctionPipe implements PipeTransform {
  transform(func: (...params) => any, ...params) {
    return func(...params);
  }
}


@Pipe({
  name: 'xFunction',
  standalone: true
})

export class FunctionStandalonePipe implements PipeTransform {
  transform(func: (...params) => any, ...params) {
    return func(...params);
  }
}
