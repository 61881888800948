import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UpsertComponent } from 'src/app/shared/_components/_base-component/upsert.component';
import { TaskService } from '../../_services/task.service';
import { takeUntil } from 'rxjs';
import { Task, TaskCloneRequest } from 'src/app/shared';

@Component({
  selector: 'app-task-template-upsert',
  templateUrl: './task-template-upsert.component.html',
  styleUrls: ['./task-template-upsert.component.scss']
})
export class TaskTemplateUpsertComponent extends UpsertComponent<Task> {
  @Input() task: Task;

  constructor(
    fb: FormBuilder,
    private _task: TaskService,
  ) {
    super(fb)
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.task?.name || null, [Validators.required]],
      cloneTemplate: [true],
      cloneSubTask: [true],
    });
  }

  add(): void {
    const payload: TaskCloneRequest = {
      taskIds: [this.task.id],
      ...this.formValue
    };

    this._task.cloneTasks(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: res => {
          const template = res?.tasks[0];
          if (template) {
            this.objectResponse.emit(template);
          }
        },
        error: (error: string) => {
          this.errorFn(error);
          this.objectResponse.next(null);
        }
      });
  }
}
