import { createSelector } from '@ngrx/store';
import { AppState } from './app.reducers';
import { RootState } from '.';
import { getRouterSelectors } from '@ngrx/router-store';

export const selectApp = (state: RootState) => state.app;

export const selectLoading = createSelector(
  selectApp,
  (state: AppState) => state.loading
);

export const selectDefaultPageUrl = createSelector(
  selectApp,
  (state: AppState) => state.defaultUrlPage
);

export const selectEditorMode = createSelector(
  selectApp,
  (state: AppState) => state.editorMode
);

export const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectRouteDataParam,
  selectUrl,
  selectTitle,
} = getRouterSelectors();

export const selectProgressLoading = createSelector(
  selectApp,
  (state: AppState) => state.progressLoading
);
