import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LogTimeResponse } from 'src/app/shared/_models/time-log.model';

export interface LogWorkChange {
  totalTime: number;
  detail: LogTimeResponse[];
}

@Injectable({
  providedIn: 'root'
})
export class TaskDetailDataService {
  currentWidth$: Subject<number> = new Subject();
  logWorkChange$: Subject<LogWorkChange> = new Subject();
}
