export enum TaskFilterMode {
  Basic = 'BASIC',
  Advance = 'ADVANCE',
  SQL = 'XQL'
}

export enum FilterType {
  Dropdown = 'dropdown',
  SingleDatePicker = 'singleDatePicker',
  PeriodDatePicker = 'periodDatePicker',
  Text = 'text',
  CustomQuery = 'customQuery'
}

export enum LogicOperator {
  NOT = 'not',
  AND = 'and',
  OR = 'or'
}

export enum QueryToken {
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '<=',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL = '>=',
  EQUAL = '==',
  NOT_EQUAL = '!=',
  LCB = '(',
  RCB = ')',
  NULL = 'null',
  TRUE = 'true',
  FALSE = 'false',
  IN = 'in',
  NOT_IN = 'not_in',
  LIKE = 'like',
  DOT = '.'
}

export enum FieldSuggestion {
  PROJECT = 'project',
  ASSIGNEE = 'assignee',
  REPORTER = 'reporter',
  CC = 'cc',
  CREATED_BY = 'createdBy',
  CREATED_AT = 'createdAt',
  MODIFIED_AT = 'modifiedAt',
  TEAM = 'assignee.teams',
  GROUP = 'assignee.userGroups.group',
  PRIORITY = 'priority',
  KEY = 'key',
  TYPE = 'type',
  STATUS = 'backlogs.status',
  FROM_DATE = 'startDate',
  TO_DATE = 'endDate',
  DUE_DATE = 'dueDate',
  BACKLOG_STATUS_CATEGORY = 'backlogs.status.statusCategory',
  BOOKMARK_IS_BOOKMARK = 'bookmarks.isBookmark',
  BOOKMARK_USER = 'bookmarks.user',
  PROJECT_DELETED = 'project.deleted',
  PARENT_TASK = 'parentTask',
  NAME = 'name',
  COMMENT_FEEDBACK_USER = 'comments.commentFeedback.userIds',
  COMMENT_FEEDBACK_DATE = 'comments.commentFeedback.dateFeedback',
  ASSIGNEE_EMAIL = 'assignee.email',
  REPORTER_EMAIL = 'reporter.email',
  CC_EMAIL = 'cc.email',
  CREATED_BY_EMAIL = 'createdBy.email',
  BOOKMARK_USER_EMAIL = 'bookmarks.user.email',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  PROJECT_ID = 'project.id',
  ASSIGNEE_ID = 'assignee.id',
  STATUS_ID = 'backlogs.status',
  GROUP_STATUS_ID = 'backlogs.status.boardGroupStatus.id',
  SPRINT_ID = 'backlogs.sprint.id',
}

export enum KeyFilterBy {
  PROJECT = 'project',
  REPORTER = 'reporter',
  ASSIGNEE = 'assignee',
  CC = 'cc',
  CREATED_BY = 'createdBy',
  TEAM = 'team',
  GROUP = 'group',
  PERIOD = 'period',
  PRIORITY = 'priority',
  TASK_KEY = 'taskKey',
  TASK_STATUS = 'taskStatus',
  STATUS_CATEGORY = 'statusCategory',
  TASK_TYPE = 'taskType',
  DUE_DATE = 'dueDate',
  CREATED_DATE = 'createdAt',
  UPDATED_DATE = 'modifiedAt',
  ADVANCE_FILTER = 'advanceFilter'
}

export enum ShareFilterPermission {
  VIEW = 'View',
  EDIT = 'Edit',
  DIFFERENT = 'Different'
}

export enum ShareFilterTarget {
  USER = 'USER',
  TEAM = 'TEAM',
  GROUP = 'GROUP',
}

export enum TaskFilterTab {
  MY_FILTERS = 'taskMgmtMyFilter',
  SHARED_FILTER = 'taskMgmtSharedFilter'
}

export enum NavigatedFilterName {
  BACKLOG_CLOSED_TASKS = 'backlogClosedTasks'
}
