<div class="task-detail-form-wrapper" [formGroup]="form">
  <div class="form-group" *ngIf="visibleSystemField('TD_PROGRESS')">
    <label><app-i18n [key]="'Progress'"></app-i18n></label>
    <app-task-progress class="flex-grow-1" [control]="form.get('progress')" (onSubmit)="onProgressChange()"></app-task-progress>
  </div>

  <app-task-reminder [task]="task" [form]="form"></app-task-reminder>

  <div class="form-group" *ngIf="visibleSystemField('ASSIGNEE')">
    <label><app-i18n [key]="'Assignee'"></app-i18n></label>
    <div class="control">
      <ng-select
        #assignee
        formControlName="assignee"
        placeholder="{{'Unassigned' | translate}}"
        [items]="assignees"
        bindLabel="fullName"
        [searchable]="true"
        [clearable]="true"
        [multiple]="false"
        [loading]="assignee?.isOpen && loading.assignee"
        (open)="loading.assignee && getUser()"
        [typeahead]="assigneeTypeahead$">

        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="custom-option">
            <app-avatar
              [url]="item.avatar"
              [name]="item.fullName"
            ></app-avatar>
            <span class="option-label">{{item.fullName}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('REPORTER')">
    <label><app-i18n [key]="'Reporter'"></app-i18n></label>
    <div class="control">
      <ng-select
        #reporter
        formControlName="reporter"
        placeholder="{{'Unassigned' | translate}}"
        [items]="reporters"
        bindLabel="fullName"
        [searchable]="true"
        [clearable]="false"
        [multiple]="false"
        [loading]="reporter?.isOpen && loading.reporter"
        (open)="loading.reporter && getUser()"
        [typeahead]="reporterTypeahead$">

        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="custom-option">
            <app-avatar
              [url]="item.avatar"
              [name]="item.fullName"
            ></app-avatar>
            <span class="option-label">{{item.fullName}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('CC')">
    <label><app-i18n [key]="'CC'"></app-i18n></label>
    <div class="control">
      <ng-select
        #cc
        formControlName="ccs"
        placeholder="{{'None' | translate}}"
        [items]="ccs"
        bindLabel="fullName"
        [searchable]="true"
        [clearable]="true"
        [multiple]="true"
        [loading]="cc?.isOpen && loading.cc"
        (open)="loading.cc && getUser()"
        [typeahead]="ccTypeahead$">

        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="custom-option mr-1" *ngFor="let item of items">
            <app-avatar
              [url]="item.avatar"
              [name]="item.fullName"
            ></app-avatar>
            <span class="option-label">{{item.fullName}}</span>
            <span class="material-icons btn-clear ng-value-icon" (click)="clear(item)"> clear </span>
          </div>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          <div class="custom-option mr-1">
            <app-avatar
              [url]="item.avatar"
              [name]="item.fullName"
            ></app-avatar>
            <span class="option-label">{{item.fullName}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('TYPE')">
    <label><app-i18n [key]="'Type'"></app-i18n></label>
    <div class="control">
      <ng-select
        #type
        formControlName="type"
        placeholder="{{'None' | translate}}"
        [items]="types"
        bindLabel="name"
        [searchable]="false"
        [clearable]="false"
        [loading]="type?.isOpen && loading.type"
        (open)="loading.type && getTypes()"
        [multiple]="false">

        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="custom-option">
            <div class="option-icon-wrap">
              <img class="option-icon" [src]="item.icon" />
            </div>
            <span class="option-label"><app-i18n-master-data [object]="item"></app-i18n-master-data></span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('PRIORITY')">
    <label><app-i18n [key]="'Priority'"></app-i18n></label>
    <div class="control">
      <ng-select
        #priority
        formControlName="priority"
        placeholder="{{'None' | translate}}"
        [items]="priorities"
        bindLabel="name"
        [searchable]="false"
        [clearable]="false"
        [multiple]="false"
        [loading]="priority?.isOpen && loading.priority"
        (open)="loading.priority && getPriorities()">

        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="custom-option">
            <div class="option-icon-wrap">
              <img class="option-icon" [src]="item.icon" />
            </div>
            <span class="option-label"><app-i18n-master-data [object]="item"></app-i18n-master-data></span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-group" *ngIf="canShowEpicField()">
    <label><app-i18n [key]="'Epic link'"></app-i18n></label>
    <div class="control">
      <ng-select
        #epic
        formControlName="epic"
        placeholder="None"
        [items]="epics"
        bindLabel="name"
        [searchable]="true"
        [clearable]="true"
        [multiple]="false"
        [typeahead]="epicTypeahead$"
        [loading]="epic?.isOpen && loading.epic"
        (open)="loading.epic && getEpics()">

        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="custom-option">
            <span class="badge-label text-ellipsis" [ngStyle]="{'background-color': item.colorCode}">{{item.name}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="form-group" *ngIf="task | canShowColorField">
    <label><app-i18n [key]="'Color'"></app-i18n></label>
    <div class="pl10">
      <app-epic-colors-dropdown-list
        [control]="form.get(TaskDetailFormType.ColorCode)">
      </app-epic-colors-dropdown-list>
    </div>
  </div>

  <div class="form-group" *ngIf="canSetPlatform() && visibleSystemField('TD_PLATFORM')">
    <label><app-i18n [key]="'Platform'"></app-i18n></label>
    <div class="control">
      <ng-select
        #platform
        formControlName="platform"
        placeholder="{{'None' | translate}}"
        [items]="availablePlatforms"
        [searchable]="false"
        [clearable]="true"
        [multiple]="false"
        [loading]="platform?.isOpen && loading.platform"
        (open)="getAvailablePlatforms()">

        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          <div class="custom-option">
            <app-icon [icon]="item"></app-icon>
            <span class="ml-2">{{item.name}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="form-group" *ngIf="task && task.type?.name !== TaskTypeEnum.Storyline && visibleSystemField('STORY_LINE')">
    <label><app-i18n [key]="'Storyline'"></app-i18n></label>
    <div class="control">
      <ng-select
        #storyline
        formControlName="storyline"
        placeholder="{{'None' | translate}}"
        [items]="storylines"
        bindLabel="name"
        [searchable]="true"
        [clearable]="true"
        [multiple]="false"
        [typeahead]="storylineTypeahead$"
        [loading]="storyline?.isOpen && loading.storyline"
        (open)="getStorylines()">

        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="custom-option">
            <img class="option-icon" [src]="item.type?.icon" />
            <span class="option-label"><span class="text-primary mr-1">{{item.key}}</span>{{item.name}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('ESTIMATE')">
    <label><app-i18n [key]="'Estimate'"></app-i18n></label>
    <div class="control">
      <app-input type="number" placeholder="{{'None' | translate}}" [control]="form.get('timeOriginalEstimate')" (onSubmit)="onEstimateChange()"></app-input>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('STORY_POINT')">
    <label>
      <app-i18n [key]="'Story Point'"></app-i18n>
      <mat-icon class="info-icon"
        [svgIcon]="'info-circle-fill'"
        [matTooltip]="'contributorStoryPoint' | translate"
        *ngIf="task?.project?.multiAssigneeFlg"
      ></mat-icon>
    </label>
    <div class="control">
      <app-input type="number" placeholder="{{'None' | translate}}" [control]="form.get('storyPoint')" (onSubmit)="onStoryPointChange()"></app-input>
    </div>
  </div>
  <div class="form-group" *ngIf="isStoryline() && visibleSystemField('TD_PLATFORM')">
    <label class="platform-label"><app-i18n [key]="'Platform Estimate'"></app-i18n></label>
    <div class="control estimate-control">
      <div class="estimate-item" *ngFor="let estimate of form.get('storylineEstimates')?.value">
        <div class="form-control disabled platform">
          <app-icon [icon]="estimate?.platform" *ngIf="estimate?.platform"></app-icon>

          <span class="ml-2">{{estimate?.platform?.name}}</span>
        </div>

        <app-input [type]="'number'" [value]="estimate.value" (onSubmit)="estimate.value = $event; onStorylineEstimatesChange()"></app-input>
      </div>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('LABELS')">
    <label><app-i18n [key]="'Labels'"></app-i18n></label>
    <div class="control">
      <ng-select
        #label
        formControlName="labels"
        placeholder="{{'None' | translate}}"
        [items]="labels"
        class="label-control"
        bindLabel="name"
        [searchable]="true"
        [clearable]="true"
        [multiple]="true"
        [typeahead]="labelTypeahead$"
        [loading]="label?.isOpen && loading.label"
        (open)="loading.label && getLabels()">

        <ng-template ng-multi-label-tmp  let-items="items" let-clear="clear">
          <div class="custom-option mr-1" *ngFor="let item of items">
            <span class="badge-label text-ellipsis" [style.backgroundColor]="item.colorCode">{{item.name}}</span>
            <span class="material-icons btn-clear ng-value-icon" (click)="clear(item)"> clear </span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-group" *ngIf="!isKanbanLicense() && visibleSystemField('RELEASE')">
    <label><app-i18n [key]="'Releases'"></app-i18n></label>
    <div class="control">
      <ng-select
        #version
        formControlName="versions"
        placeholder="{{'None' | translate}}"
        [items]="versions"
        bindLabel="name"
        [searchable]="true"
        [clearable]="true"
        [multiple]="true"
        [typeahead]="versionTypeahead$"
        [loading]="version?.isOpen && loading.version"
        (open)="loading.version && getVersions()">

        <ng-template ng-multi-label-tmp  let-items="items" let-clear="clear">
          <div class="custom-option mr-1" *ngFor="let item of items">
            <span class="badge-label text-ellipsis">{{item.name}}</span>
            <span class="material-icons btn-clear ng-value-icon" (click)="clear(item)"> clear </span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('SPRINT')">
    <label><app-i18n [key]="this.task?.project | projectBoardName"></app-i18n></label>
    <div class="control">
      <ng-select *ngIf="task && task.type?.name !== 'Sub Task'"
        #sprint
        formControlName="sprint"
        placeholder="{{'None' | translate}}"
        [items]="sprints"
        bindLabel="name"
        [searchable]="true"
        [clearable]="true"
        [multiple]="false"
        [typeahead]="sprintTypeahead$"
        [loading]="sprint?.isOpen && loading.sprint"
        (open)="loading.sprint && getSprints()">
      </ng-select>

      <span class="form-control text-only" *ngIf="task && task.type?.name === 'Sub Task'">{{form.get('sprint').value?.name || ''}}</span>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('METRIC')">
    <label><app-i18n [key]="'Metric'"></app-i18n></label>
    <div class="control">
      <ng-select
        #metric
        formControlName="metric"
        placeholder="{{'None' | translate}}"
        [items]="metrics"
        bindLabel="name"
        [searchable]="true"
        [clearable]="true"
        [multiple]="false"
        [loading]="metric?.isOpen && loading.metric"
        (open)="loading.metric && getMetrics()">
      </ng-select>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('TD_START_METRIC_VALUE')">
    <label class="metric-label"><app-i18n [key]="'Start Metric Value'"></app-i18n>
      {{form.get('metric')?.value?.unit ? '('+form.get('metric')?.value?.unit+')' : ''}}
    </label>
    <div class="control">
      <app-input type="number" placeholder="{{'None' | translate}}" [control]="form.get('startMetricValue')" (onSubmit)="onStartMetricValueChange()"></app-input>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('TD_CURRENT_METRIC_VALUE')">
    <label class="metric-label"><app-i18n [key]="'Current Metric Value'"></app-i18n>
      {{form.get('metric')?.value?.unit ? '('+form.get('metric')?.value?.unit+')' : ''}}
    </label>
    <div class="control">
      <app-input type="number"
        [disabled]="!form.get('metricValue')?.value || form.get('currentMetricValue')?.disabled"
        [placeholder]="'None' | translate"
        [control]="form.get('currentMetricValue')"
        (onSubmit)="onCurrentMetricValueChange()">
      </app-input>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('TD_EXPECTED_METRIC_VALUE')">
    <label class="metric-label"><app-i18n [key]="'Expected Metric Value'"></app-i18n> {{form.get('metric')?.value?.unit ? '('+form.get('metric')?.value?.unit+')' : ''}}</label>
    <div class="control">
      <app-input type="number" placeholder="{{'None' | translate}}" [control]="form.get('metricValue')" (onSubmit)="onMetricValueChange()"></app-input>
    </div>
  </div>

  <div class="form-group" *ngIf="visibleSystemField('TD_START_DATE')">
    <label><app-i18n [key]="'Start Date'"></app-i18n></label>
    <div class="control">
      <app-datepicker [control]="form.get('startDate')"></app-datepicker>
    </div>
  </div>
  <div class="form-group" *ngIf="visibleSystemField('TD_END_DATE')">
    <label><app-i18n [key]="'End Date'"></app-i18n></label>
    <div class="control">
      <app-datepicker [control]="form.get('endDate')"></app-datepicker>
    </div>
  </div>

  <div class="form-group">
    <label><app-i18n [key]="'Start Time'"></app-i18n></label>
    <div class="control">

      <app-timepicker
        [control]="form.get('startTime')"
        [defaultValue]="formControlLabel.StartTime | getDefaultValueTimeControl">
      </app-timepicker>
    </div>
  </div>

  <div class="form-group">
    <label><app-i18n [key]="'End Time'"></app-i18n></label>
    <div class="control">
      <app-timepicker
        [control]="form.get('endTime')"
        [defaultValue]="formControlLabel.EndTime | getDefaultValueTimeControl">
      </app-timepicker>
    </div>
  </div>

  <div class="form-group" *ngIf="visibleSystemField('DUE_DATE')">
    <label><app-i18n [key]="'Due Date'"></app-i18n></label>
    <div class="control">
      <app-datepicker [control]="form.get('dueDate')"></app-datepicker>
    </div>
  </div>

  <div class="form-group" *ngIf="visibleSystemField('FOLLOW_UP')">
    <label><app-i18n [key]="'Follow Up'"></app-i18n>
      <mat-icon class="info-icon" [svgIcon]="'info-circle-fill'" [matTooltip]="'checkedFollowTask' | translate"></mat-icon>
    </label>
    <div class="control">
      <label class="checkbox-control">
        <input #input type="checkbox" formControlName="followUp" id="followUp">
      </label>
    </div>
  </div>
  <div class="form-group" *ngIf="!task.taskTemplate">
    <label><app-i18n [key]="'Task Template'"></app-i18n>
      <mat-icon class="info-icon" [svgIcon]="'info-circle-fill'"></mat-icon>
    </label>
    <div class="control">
       <div class="checkbox-control d-flex-center" *ngIf="form.get('template').value as template; else createTaskTemplate">
        <div class="task-key-detail">
          <img class="task-icon" [src]="template.type?.icon" alt="">
          <a class="btn-link" (click)="onOpenTask(template)">{{template?.key}}</a>
        </div>
        <button class="btn btn-link btn-flex mx-2" (click)="unlinkTaskTemplate()" *ngIf="task?.isEditable">
          <span class="material-icons-outlined icon-md mr-1"> link_off </span> Unlink
        </button>
      </div>

      <ng-template #createTaskTemplate>
        <button class="btn btn-link" (click)="onCreateTemplate()" *ngIf="task?.isEditable">
          <app-i18n [key]="'Create Task Template'"></app-i18n>
        </button>
      </ng-template>
    </div>
  </div>
  <div class="form-group">
    <label><app-i18n [key]="'Private'"></app-i18n>
    </label>
    <div class="control">
      <label class="checkbox-control">
        <input #input type="checkbox" formControlName="privateFlg" id="privateFlg">
      </label>
    </div>
  </div>
  <app-d-field
    [fields]="fields"
    [hideSystem]="true"
    (fieldChange)="onFieldChange($event)">
  </app-d-field>
</div>
