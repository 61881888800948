import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { Pageable, PageInfo, TaskContributor, TaskContributorRequest } from 'src/app/shared';
import { TaskContributorService } from '../../_services/task-contributor.service';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ToastService } from 'src/app/core';
import { AppErrorHandler } from 'src/@xcorp/services/error/app-error-handler';
import { LogWorkChange, TaskDetailDataService } from '../services/task-detail-data.service';
import { head } from 'lodash';

export const contributorAdapter = createEntityAdapter<TaskContributor>({
  selectId: e => e.id
});
export const contributorSelectors = contributorAdapter.getSelectors();

export interface TaskContributorState {
  contributors: EntityState<TaskContributor>;
  overallProgress: number;
  taskId: number;
  pageInfo: PageInfo;
}

const initialState: TaskContributorState = {
  contributors: contributorAdapter.getInitialState(),
  overallProgress: null,
  taskId: null,
  pageInfo: null,
};

@Injectable()
export class TaskContributorStore extends ComponentStore<TaskContributorState> {
  constructor(
    private _contributor: TaskContributorService,
    private _taskDetailData: TaskDetailDataService,
    private _toast: ToastService,
  ) {
    super(initialState);
  }

  // Getter
  get contributors() {
    return this.get().contributors;
  }

  get overallProgress() {
    return this.get().overallProgress;
  }

  get pageInfo() {
    return this.get().pageInfo;
  }

  get taskId() {
    return this.get().taskId;
  }

  // Selectors
  readonly contributors$ = this.select(state => contributorSelectors.selectAll(state.contributors));
  readonly pageInfo$ = this.select(state => state.pageInfo);

  // Effects
  readonly fetchContributors = this.effect(
    ($params: Observable<{ taskId: number, pageable: Pageable }>) => {
      return $params.pipe(
        switchMap(({ taskId, pageable }) => {
          return this._contributor.getAll(taskId, pageable)
            .pipe(
              tapResponse(
                (res) => {
                  this._setPageInfo(PageInfo.mapFromList2Res(res));
                  this._setContributors(res.content);
                  this._setOverallProgress(res.content);
                },
                (error: string) => {
                  AppErrorHandler.display(error);
                  return EMPTY;
                }
              )
            );
        })
      );
    }
  );

  readonly addContributor = this.effect(
    ($params: Observable<{ payload: TaskContributorRequest }>) => {
      return $params.pipe(
        switchMap(({ payload }) => {
          return this._contributor.add(this.taskId, payload)
            .pipe(
              tapResponse(
                (res) => {
                  this._toast.success('Added Contributor Successfully!');
                  this.fetchContributors({
                    taskId: this.taskId,
                    pageable: new Pageable()
                  });
                },
                (error: string) => {
                  AppErrorHandler.display(error);
                  return EMPTY;
                }
              )
            );
        })
      );
    }
  );

  readonly updateContributor = this.effect(
    ($params: Observable<{ id: number, payload: TaskContributorRequest }>) => {
      return $params.pipe(
        switchMap(({ id, payload }) => {
          return this._contributor.update(id, payload)
            .pipe(
              tapResponse(
                (res) => {
                  this._toast.success('Updated Contributor Successfully!');
                  this._updateContributor(res);
                },
                (error: string) => {
                  AppErrorHandler.display(error);
                  return EMPTY;
                }
              )
            );
        })
      );
    }
  );

  readonly deleteContributor = this.effect(
    ($params: Observable<{ id: number }>) => {
      return $params.pipe(
        switchMap(({ id }) => {
          return this._contributor.delete(id)
            .pipe(
              tapResponse(
                (res) => {
                  this._toast.success('Deleted Contributor Successfully!');
                  this.fetchContributors({
                    taskId: this.taskId,
                    pageable: new Pageable()
                  })
                },
                (error: string) => {
                  AppErrorHandler.display(error);
                  return EMPTY;
                }
              )
            );
        })
      );
    }
  );

  readonly observeLogWorkChange = this.effect(() => {
    return this._taskDetailData.logWorkChange$.pipe(
      tapResponse(
        ({ totalTime, detail }: LogWorkChange) => {
          const loggedUserId = head(detail).user.id;

          // Find the contributor with matching user.id and update logWorkTime
          const contributorToUpdate = contributorSelectors
            .selectAll(this.get().contributors)
            .find(contributor => contributor.user.id === loggedUserId);

          if (contributorToUpdate) {
            const updatedContributor = {
              ...contributorToUpdate,
              logWorkTime: totalTime
            };

            this._updateContributor(updatedContributor);
          }
        },
        (error: string) => EMPTY
      )
    );
  });

  // Actions
  readonly _setContributors = (data: TaskContributor[]) =>
    this.patchState({ contributors: contributorAdapter.setAll(data, this.contributors) });
  

  readonly _updateContributor = (data: TaskContributor) => {
    return this.patchState({ contributors: contributorAdapter.upsertOne(
      data,
      this.contributors,
    )});
  }

  readonly _setPageInfo = (pageInfo: PageInfo) => this.patchState({ pageInfo });
  readonly _setTaskId = (taskId: number) => this.patchState({ taskId });
  readonly _setOverallProgress = (contributors: TaskContributor[]) => {
    const total = contributors.reduce((acc, cur) => acc + cur.progress, 0);
    return this.patchState({ overallProgress: total / (contributors.length) });
  };
}
