import { NgModule } from '@angular/core';
import { AutoWidthDirective } from './auto-width.directive';
import { CustomDropdownDirective } from './custom-dropdown.directive';
import { CustomTooltipDirective } from './custom-tooltip.directive';
import { ModifierClickDirective } from './modifier-click.directive';
import { MouseHoverDirective } from './mouse-hover.directive';

const DIRECTIVES = [
  AutoWidthDirective,
  CustomDropdownDirective,
  CustomTooltipDirective,
  ModifierClickDirective,
  MouseHoverDirective
]

@NgModule({
  declarations: [
    ...DIRECTIVES,
  ],
  exports: [
    ...DIRECTIVES,
  ]
})
export class XDirectivesModule { }
