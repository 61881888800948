import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, startWith, takeUntil, debounceTime } from 'rxjs';
import { AvatarModule } from 'src/@xcorp/components/avatar/avatar.module';
import { InputComponent } from 'src/@xcorp/components/input/input.component';
import { DATE_UI_FORMAT, Pageable, Task, TaskContributor, TaskContributorRequest, User, UserShort } from 'src/app/shared';
import { UpsertComponent } from 'src/app/shared/_components/_base-component/upsert.component';
import { ModalContainerModule } from 'src/app/shared/_components/modal-container/modal-container.module';
import { DateModule } from 'src/@xcorp/components/date/date.module';
import { TimeComponent } from 'src/@xcorp/components/time/time.component';
import { TimeValidators, UtilService } from 'src/app/core';
import { ProgressComponent } from 'src/@xcorp/components/progress/progress.component';
import { XPipesModule } from 'src/@xcorp/pipes/x-pipes.module';
import { TaskDetailTimesheetComponent } from '../../task-detail-timesheet/task-detail-timesheet.component';
import { TimeDurationService } from 'src/app/core/_services/time-duration.service';
import { FormControlLabel } from '../../task-detail-form/task-detail-form.component';
import { TaskDetailDataService } from '../../services/task-detail-data.service';
import { TaskContributorService } from '../../../_services/task-contributor.service';
import { AuthService } from 'src/app/authentication/_services/auth.service';

const MODULES = [
  CommonModule,
  ModalContainerModule,
  AvatarModule,
  NgSelectModule,
  TranslateModule,
  DateModule,
  FormsModule,
  ReactiveFormsModule,
  XPipesModule,
];
const COMPONENTS = [InputComponent, TimeComponent, ProgressComponent];

// Currently can not use the component like standalone because it causes a circular dependency in task-shared module
@Component({
  selector: 'x-task-detail-contributor-upsert',
  // standalone: true,
  // imports: [...MODULES, ...COMPONENTS],
  templateUrl: './task-detail-contributor-upsert.component.html',
  styleUrls: ['./task-detail-contributor-upsert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TaskDetailContributorUpsertComponent extends UpsertComponent<TaskContributor> {
  @Input() task!: Task;
  @Input() contributors: TaskContributor[];
  @Output() payloadResponse = new EventEmitter<TaskContributorRequest>();
  @ViewChild('timeSheet') timeSheet: TaskDetailTimesheetComponent;

  loggedUser!: User;
  users: UserShort[] = [];
  DATE_FORMAT = DATE_UI_FORMAT;
  userTypeahead$ = new Subject<string>();
  overallProgress!: number;
  formControlLabel = FormControlLabel;
  baseOverallProgress!: number;

  get projectId() {
    return this.task.project.id;
  }

  constructor(
    protected _fb: FormBuilder,
    private _auth: AuthService,
    private _timeDuration: TimeDurationService,
    private _taskContributor: TaskContributorService,
    private _taskDetailData: TaskDetailDataService,
  ) {
    super(_fb);
  }

  init() {
    super.init();
    this.observeEvents();
    this.baseOverallProgress = this.getBaseOverallProgress();
    this.overallProgress = this.calcOverallProgress();
    this.loggedUser = this._auth.loggedUser;
  }

  createForm(): void {
    this.form = this._fb.group({
      progress: [this.object?.progress ?? 0],
      user: [this.object?.user ?? null, [Validators.required]],
      storyPoint: [this.object?.storyPoint ?? null],
      estimate: [this.object?.estimate ?? null],
      logWorkTime: [{
        value: this.getLogTime(this.object?.logWorkTime),
        disabled: true,
      }],
      startDate: [this.object?.startDate ?? null],
      endDate: [this.object?.endDate ?? null],
      dueDate: [this.object?.dueDate ?? null],
      startTime: [
        this.object?.startTime ? UtilService.convertFullTimeTo12Hours(this.object.startTime) : null,
        [TimeValidators.time12HourFormatValidator()]
      ],
      endTime: [
        this.object?.endTime ? UtilService.convertFullTimeTo12Hours(this.object.endTime) : null,
        [TimeValidators.time12HourFormatValidator()]
      ],
    });

    if (this.object) {
      this.form.get('user').disable();
    }
  }

  observeEvents() {
    this.userTypeahead$
      .pipe(startWith(''), takeUntil(this.destroyed$), debounceTime(200))
      .subscribe(keyword => {
        this.getAvailableContributors(keyword);
      });

    this.form.get('progress').valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        this.overallProgress = this.calcOverallProgress(value);
      });

    this._taskDetailData.logWorkChange$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(logWork => {
        const { totalTime } = logWork;
        this.form.get('logWorkTime').setValue(this.getLogTime(totalTime), { emitEvent: false });
      });
  }

  getAvailableContributors(keyword = '') {
    this._taskContributor.getAvailableContributors(this.task.id, keyword, new Pageable({ size: 1000 }))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: response => {
          this.users = response.content;
        },
        error: () => {
          this.users = [];
        }
      })
  }

  getBaseOverallProgress() {
    return this.contributors.reduce(
      (acc, contributor) => acc + contributor.progress,
      0
    );
  }

  calcOverallProgress(currentProgress = (this.object?.progress || 0)): number {
    if (this.object) {
      const adjustedProgress = this.contributors.reduce(
        (acc, contributor) => 
          acc + (contributor.id === this.object?.id ? currentProgress : contributor.progress),
        0
      );
      return Math.floor(adjustedProgress / this.contributors.length);
    } else {
      return Math.floor(
        (this.baseOverallProgress + currentProgress) / (this.contributors.length + 1)
      );
    }
  }

  getLogTime(duration: number) {
    return this._timeDuration.getHumanizeDuration(duration);
  }

  canShowTimeSheet(contributor: TaskContributor, loggedUser: User) {
    return contributor?.user?.id === loggedUser.id;
  }

  setupPayload() {
    const formValue = this.form.getRawValue();

    const payload: TaskContributorRequest = {
      progress: formValue.progress,
      storyPoint: formValue.storyPoint,
      dueDate: UtilService.getEndOfDay(formValue.dueDate),
      startDate: UtilService.getStartOfDay(formValue.startDate),
      endDate: UtilService.getEndOfDay(formValue.endDate),
      startTime: formValue.startTime ? UtilService.convert12HoursToFullTime(formValue.startTime) : null,
      endTime: formValue.endTime ? UtilService.convert12HoursToFullTime(formValue.endTime) : null,
    }

    if (!this.object) {
      payload.userId = formValue.user.id;
    }

    return payload;
  }

  submit() {
    const payload = this.setupPayload();
    this.payloadResponse.next(payload);
  }

  onCancel() {
    this.payloadResponse.next(null);
  }
}
