import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Directive({ selector: '[mouseHover]', exportAs: 'mouseHover' })
export class MouseHoverDirective {
  @Input() delayTime = 1000;
  @Input() delayEndTime = 100;
  @Output() hover = new EventEmitter<MouseEvent>();
  @Output() hoverEnd = new EventEmitter<MouseEvent>();
  @Output() hoverMove = new EventEmitter<MouseEvent>();

  private hoverTimeout: any;
  private hoverEndTimeout: any;
  public hoverState: 'start' | 'start-execution' | 'end' = null;
  public hovering = false;

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    this.hoverState = 'start';
    clearTimeout(this.hoverTimeout);

    this.hoverTimeout = setTimeout(() => {
      this.hoverState = 'start-execution';
      this.hover.emit(event);
    }, this.delayTime);
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    this.hovering = true;
    this.hoverMove.emit(event);
  }

  @HostListener('mouseleave')
  onMouseLeave(event: MouseEvent): void {
    this.hovering = false;
    this.hoverState = 'end';
    clearTimeout(this.hoverTimeout);
    clearTimeout(this.hoverEndTimeout);

    this.hoverEndTimeout = setTimeout(() => {
      this.hoverEnd.emit(event);
    }, this.delayEndTime);
  }
}
