import { Sprint } from "src/app/shared";

export interface BacklogContextMenuItem {
  id?: number;
  sprint?: Sprint;
  name: string;
  code: string;
  children?: BacklogContextMenuItem[];
  disabled?: boolean;
}

export enum ContextMenuCode {
  MOVE_TO = 'MOVE_TO',
  DELETE_TASK = 'DELETE_TASK',
  SET_TASK_TEMPLATE = 'SET_TASK_TEMPLATE',
  BULK_CHANGE = 'BULK_CHANGE',
  MOVE_TO_SPRINT = 'MOVE_TO_SPRINT',
  MOVE_TO_TOP_BACKLOG = 'MOVE_TO_TOP_BACKLOG',
  MOVE_TO_BOTTOM_BACKLOG = 'MOVE_TO_BOTTOM_BACKLOG',
  CLONE_TASKS = 'CLONE_TASKS'
}

export enum LabelSubMenu {
  nextSprint = 'Next Sprint',
  topOfBacklog = 'Top of Backlog',
  bottomOfBacklog = 'Bottom of Backlog'
}

export const BacklogContextMenuStructure: BacklogContextMenuItem[] = [
  {
    name: 'Move To',
    code: ContextMenuCode.MOVE_TO,
    children: [
      {
        name: 'Top of Backlog',
        code: ContextMenuCode.MOVE_TO_TOP_BACKLOG
      },
      {
        name: 'Bottom of Backlog',
        code: ContextMenuCode.MOVE_TO_BOTTOM_BACKLOG
      }
    ]
  },
  {
    name: 'Clone Task',
    code: ContextMenuCode.CLONE_TASKS,
  },
  {
    name: 'Delete Task',
    code: ContextMenuCode.DELETE_TASK
  },
  // {
  //   name: 'Set to Task Templates',
  //   code: ContextMenuCode.SET_TASK_TEMPLATE
  // },
  {
    name: 'Bulk Change',
    code: ContextMenuCode.BULK_CHANGE
  }
];

export enum BulkTaskFieldType {
  statusId = 'statusId',
  assigneeId = 'assigneeId',
  reporterId = 'reporterId',
  ccIds = 'ccIds',
  typeId = 'typeId',
  priorityId = 'priorityId',
  epicId = 'epicId',
  versionIds = 'versionIds',
  sprintId = 'sprintId',
  labelIds = 'labelIds',
  dueDate = 'dueDate',
  endDate = 'endDate',
  startDate = 'startDate',
  platformId = 'platformId',
  storylineId = 'storylineId',
  followUp = 'followUp'
}

export const TaskDetailFields = [
  {
    name: 'Status',
    value: 'status',
    type: BulkTaskFieldType.statusId,
    template: 'statusTemplate',
  },
  {
    name: 'Assignee',
    value: 'assignee',
    type: BulkTaskFieldType.assigneeId,
    template: 'assigneeTemplate',
  },
  {
    name: 'Reporter',
    value: 'reporter',
    type: BulkTaskFieldType.reporterId,
    template: 'reporterTemplate',
  },
  {
    name: 'CC',
    value: 'cc',
    type: BulkTaskFieldType.ccIds,
    template: 'ccTemplate',
  },
  {
    name: 'Type',
    value: 'type',
    type: BulkTaskFieldType.typeId,
    template: 'typeTemplate'
  },
  {
    name: 'Priority',
    value: 'priority',
    type: BulkTaskFieldType.priorityId,
    template: 'priorityTemplate',
  },
  {
    name: 'Epic',
    value: 'epic',
    type: BulkTaskFieldType.epicId,
    template: 'epicTemplate',
  },
  {
    name: 'Releases',
    value: 'releases',
    type: BulkTaskFieldType.versionIds,
    template: 'releasesTemplate',
  },
  {
    name: 'Sprint',
    value: 'sprint',
    type: BulkTaskFieldType.sprintId,
    template: 'sprintTemplate',
  },
  {
    name: 'Label',
    value: 'label',
    type: BulkTaskFieldType.labelIds,
    template: 'labelTemplate',
  },
  {
    name: 'Due Date',
    value: 'dueDate',
    type: BulkTaskFieldType.dueDate,
    template: 'dateTemplate',
  },
  {
    name: 'End Date',
    value: 'endDate',
    type: BulkTaskFieldType.endDate,
    template: 'dateTemplate',
  },
  {
    name: 'Start Date',
    value: 'startDate',
    type: BulkTaskFieldType.startDate,
    template: 'dateTemplate',
  },
  {
    name: 'Platform',
    value: 'platform',
    type: BulkTaskFieldType.platformId,
    template: 'platformTemplate'
  },
  {
    name: 'Storyline',
    value: 'storyline',
    type: BulkTaskFieldType.storylineId,
    template: 'storylineTemplate'
  },
  {
    name: 'Follow Up',
    value: 'followUp',
    type: BulkTaskFieldType.followUp,
    template: 'followUpTemplate'
  }
];

export const followUpItems = [
  { name: 'True', value: 'true' },
  { name: 'False', value: 'false'}
];


