import { Directive, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TimePickerOverlayService } from 'src/app/site-management/_services/time-picker-overlay.service';

@Directive({
  selector: '[timePicker]',
  standalone: true,
})
export class TimePickerDirective {
  @Input() control: FormControl;
  @Input() defaultValue: string;

  constructor(
    private _timePickerOverlay: TimePickerOverlayService
  ) {}

  @HostListener('click', ['$event.target'])
  onClickEvent(elementRef: Element) {
    if (elementRef) {
      this._timePickerOverlay.open(elementRef, this.control, this.defaultValue);
    }
  }
}
