import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TimePickerDirective } from 'src/@xcorp/directives/time-picker.directive';

@Component({
  selector: 'x-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, TimePickerDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeComponent implements OnInit, ControlValueAccessor {
  @Input() defaultValue: string;
  @Input() showClear: boolean = true;
  @Input() placeholder = 'None';
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  control: FormControl = new FormControl(null);

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.control.valueChanges.subscribe((res) => {
      this.onChange(res);
      this.onTouched();

      if (this.input) {
        this.input.nativeElement.value = res;
      }
    });
  }

  writeValue(value: any): void {
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  clearControlValue() {
    this.control.reset();
  }
}
