<span class="relative cursor-pointer">
  <span class="relative z-10">
    <ng-container *ngIf="control.value as date">
      <span
        class="date-control block"
        [ngClass]="{ 'opacity-50': control.disabled }"
        (click)="!control.disabled && picker.open()"
        *ngIf="date && !dueDateView"
        >{{ date | dateFormat : dateFormat }}</span
      >
      <span
        class="date-control block"
        [ngClass]="{ 'opacity-50': control.disabled }"
        [title]="(date | dueDate)?.dueDateDescription"
        (click)="!control.disabled && picker.open()"
        *ngIf="date && dueDateView"
        [innerHTML]="(date | dueDate)?.dueDateString"
      ></span>
    </ng-container>

    <span
      class="date-control block text-ellipsis text-[var(--x-border)]"
      (click)="!control.disabled && picker.open()"
      *ngIf="!control.value"
      >{{ 'None' | translate }}</span
    >
  </span>

  <input
    matInput
    class="absolute left-0 top-0 w-full h-full opacity-0"
    [formControl]="control"
    [matDatepicker]="picker"
    [ngClass]="{ hidden: control.disabled }"
    (dateChange)="onDateChanged($event)"
  />

  <mat-datepicker #picker></mat-datepicker>
</span>
