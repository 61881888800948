<form class="p-4" [formGroup]="form">
  <h4 class="c-modal-header"><app-i18n [key]="'Create Task Template'"></app-i18n></h4>

  <div class="c-content">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label><app-i18n [key]="'Task Name'"></app-i18n></label>
          <input class="form-control" formControlName="name">
          <app-form-error [form]="form" [controlName]="'name'"></app-form-error>
        </div>
      </div>
    </div>
  </div>

  <div class="actions d-flex-center pt-3">
    <label class="checkbox-control d-flex-center mr-auto">
      <input type="checkbox" id="cloneSubTask" formControlName="cloneSubTask">
      <label class="mb-0 ml-1" for="cloneSubTask">
        <app-i18n [key]="'Clone Subtasks'"></app-i18n>
      </label>
    </label>

    <button type="button" class="btn btn-white mr-2" (click)="onCancel()"><app-i18n [key]="'Cancel'"></app-i18n></button>
    <button type="submit" class="btn btn-primary" appRestrictMultiSubmit (click)="onSubmit()"><app-i18n [key]="'Save'"></app-i18n></button>
  </div>
</form>
