import { BulkTaskFieldType } from 'src/app/site-management/projects/backlogs/backlog-context-menu/backlog-context-menu';
import { BoardSprint, Task, UnassignedId } from '..';
import * as queryString from 'query-string';
import { UtilService } from 'src/app/core';

export interface BackLog {
  id: number;
  task: Task;
}

export class TasksPatchRequest {
  type: BulkTaskFieldType;
  value: string;
  taskIds: number[];
  sendMail?: boolean;

  constructor(data: TasksPatchRequest) {
    this.type = data.type;
    this.taskIds = data.taskIds;
    this.sendMail = data.sendMail;
    switch(data.type) {
      case BulkTaskFieldType.startDate:
        this.value = UtilService.getStartOfDay(data.value);
        break;
      case BulkTaskFieldType.endDate:
        this.value = UtilService.getEndOfDay(data.value);
        break;
      case BulkTaskFieldType.dueDate:
        this.value = UtilService.getEndOfDay(data.value);
        break;
      default:
        this.value = data.value;
    }
  }
}

export interface SprintToBacklogRequest {
  projectId: number;
  objectIds: number[];
  nextObjectId: number;
  preObjectId: number;
  top: boolean;
}

export interface BackLogSearchRequest {
  keyword?: string;
  fromDate?: string;
  toDate?: string;
  projectIds?: number[];
  excludeTaskIds?: number[];
}

export interface BacklogContextMenuActionParams {
  tasks: Task[];
  positionTop?: boolean;
  sprint?: BoardSprint;
  isTaskBacklog?: boolean;
}

export class BacklogSearchParam {
  projectId: number;
  keyword: string;
  taskTypeIds?: number[];
  taskIds?: number[];
  epicIds?: number[];
  userIds?: number[];
  reporterIds?: number[];
  priorityIds?: number[];
  taskStatusIds?: number[];
  sprintIds?: number[];
  releaseIds?: number[];
  platformIds?: number[];
  labelIds?: number[];
  metricIds?: number[];
  groupIds?: number[];
  teamIds?: number[];
  fromDate?: string;
  toDate?: string;
  privateFlg: boolean | null;
  showClosedTaskFlg: boolean;
  includeUnassigned?: boolean;
  page = 0;
  size = 10000;
  sort: string[];

  constructor(projectId: number) {
    this.projectId = projectId;
    this.keyword = '';
    this.taskTypeIds = [];
    this.taskIds = [];
    this.epicIds = [];
    this.priorityIds = [];
    this.taskStatusIds = [];
    this.platformIds = [];
    this.userIds = [];
    this.reporterIds = [];
    this.sprintIds = [];
    this.releaseIds = [];
    this.sort = [];
    this.showClosedTaskFlg = false;
    this.labelIds = [];
    this.metricIds = [];
    this.teamIds = [];
    this.groupIds = [];
  }

  public toQueryString(): string {
    let query = `${queryString.stringify({keyword: this.keyword || ''})}&projectIds=${this.projectId}&page=${this.page || ''}&size=${this.size || ''}`;
    query += `&showClosedTaskFlg=${this.showClosedTaskFlg}`;

    if (this.sort.length > 0) {
      query += '&' + queryString.stringify({ sort: this.sort });
    } else {
      query += '&' + queryString.stringify({ sort: `createdAt,asc` });
    }

    if (this.taskIds.length > 0) {
      query += '&' + queryString.stringify({ typeIds: this.taskIds });
    }

    if (this.taskTypeIds.length > 0) {
      query += '&' + queryString.stringify({ typeIds: this.taskTypeIds });
    }

    if (this.epicIds.length > 0) {
      query += '&' + queryString.stringify({ epicIds: this.epicIds });
    }

    if (this.priorityIds.length > 0) {
      query += '&' + queryString.stringify({ priorityIds: this.priorityIds });
    }

    if (this.taskStatusIds.length > 0) {
      query += '&' + queryString.stringify({ statusIds: this.taskStatusIds });
    }

    if (this.reporterIds.length > 0) {
      query += '&' + queryString.stringify({ reporterIds: this.reporterIds });
    }

    if (this.userIds.length > 0) {
      const filteredUserIds = this.userIds.filter((i) => i !== UnassignedId);
      const unAssigned = this.userIds.some((i) => i === UnassignedId);
      query +=
        '&' +
        queryString.stringify({
          userIds: filteredUserIds,
          unAssigned
        });
    }

    if (this.sprintIds.length > 0) {
      query += '&' + queryString.stringify({ sprintIds: this.sprintIds });
    }

    if (this.releaseIds.length > 0) {
      query += '&' + queryString.stringify({ releaseIds: this.releaseIds });
    }

    if (this.platformIds.length > 0) {
      query += '&' + queryString.stringify({ platformIds: this.platformIds });
    }

    if (this.labelIds.length > 0) {
      query += '&' + queryString.stringify({ labelIds: this.labelIds });
    }

    if (this.metricIds.length > 0) {
      query += '&' + queryString.stringify({ metricIds: this.metricIds });
    }

    if (this.groupIds.length > 0) {
      query += '&' + queryString.stringify({ groupIds: this.groupIds });
    }

    if (this.teamIds.length > 0) {
      query += '&' + queryString.stringify({ teamIds: this.teamIds });
    }

    if (this.fromDate) {
      query += '&' + queryString.stringify({ fromDate: this.fromDate });
    }

    if (this.toDate) {
      query += '&' + queryString.stringify({ toDate: this.toDate });
    }

    query +=
      '&' +
      queryString.stringify({ includeUnassigned: this.includeUnassigned });

    return query;
  }
}
