import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { environment } from 'src/environments/environment';
import { User } from '../../_models';
import { NotificationRequest, NotificationSearch, NotificationShort } from '../../_models/notification.model';
import { CreatedAtSortType } from '../../_utils/_enums/table.enum';
import { SearchPageComponent } from '../_base-component';
import { NotificationPatchType, NotificationTab } from './notification-system';
import { NotificationService } from './_services/notification.service';
import { SseService } from './_services/sse.service';
import { ListenSSEChannel, infiniteScrollDistanceDefault, infiniteScrollThrottle } from '../../_utils';

@Component({
  selector: 'app-notification-system',
  templateUrl: './notification-system.component.html',
  styleUrls: ['./notification-system.component.scss']
})
export class NotificationSystemComponent extends SearchPageComponent<NotificationShort> {
  loggedUser: User;
  numberNotification = 0;
  limitNotificationDisplay = 99;
  sseRetryConnect = 10000;
  searchRequest = new NotificationSearch();
  notificationTab = NotificationTab;
  notificationSub: Subscription;
  notificationCountSub: Subscription;
  isShowNotification = true;
  infiniteScrollDistanceDefault = infiniteScrollDistanceDefault;
  infiniteScrollThrottle = infiniteScrollThrottle;
  activeTab = NotificationTab.unread;

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private sseService: SseService
  ) {
    super();
    this.loggedUser = this.authService.getLoggedUser();
    this.searchRequest.receiverId = this.loggedUser?.id;
    this.searchRequest.sort = CreatedAtSortType.desc;
  }

  init() {
    this.countUnreadNotifications();
    this.catchEventSSE();
  }

  catchEventSSE() {
    const notiEvent = event => {
      const data = JSON.parse(event.data);
      if (data.receiverId === this.loggedUser?.id) {
        this.numberNotification = data.unreadNotifications as unknown as number;
      }
    };

    const errEvent = event => {
      switch (event.eventPhase) {
        case EventSource.CLOSED:
          this.sseService.eventSource.close();
          console.log('Connection was closed.');
          setTimeout(() => {
            this.sseService.createSSE(this.sseService.sseUrl);
            this.sseService.listenSSE(ListenSSEChannel.UnreadNotification, notiEvent);
            this.sseService.listenSSE(ListenSSEChannel.Error, errEvent);
          }, this.sseRetryConnect);
          break;
      }
    };

    this.sseService.listenSSE(ListenSSEChannel.UnreadNotification, notiEvent);
    this.sseService.listenSSE(ListenSSEChannel.Error, errEvent);
  }

  onSearch(callback?: (noties: NotificationShort[]) => void) {
    this.notificationSub?.unsubscribe();
    this.notificationSub = this.notificationService
      .getNotifications(this.loggedUser?.id, this.searchRequest)
      .pipe(takeUntil(this.destroyed$)).subscribe(
        (res) => {
          if (callback) {
            callback(res.content);
          } else {
          this.rows = res.content;
          this.countUnreadNotifications();
          }
          this.pageInfo = {
            pageable: res.pageable,
            totalElements: res.totalElements,
            totalPages: res.totalPages
          };
        },
        (err: string) => {
          this.errorFn(err);
        }
      );
  }

  onShowNotifications() {
    this.searchRequest.read = false;
    this.onSearch();
  }

  countUnreadNotifications() {
    const copySearchRequest = {...this.searchRequest};
    copySearchRequest.read = false;
    this.notificationCountSub?.unsubscribe();
    this.notificationCountSub = this.notificationService
      .getNotifications(this.loggedUser?.id, copySearchRequest)
      .pipe(takeUntil(this.destroyed$)).subscribe(
        (res) => {
          this.numberNotification = res?.totalElements;
        },
        (err: string) => {
          this.errorFn(err);
        }
      );
  }

  onRemoveNotification(notification: NotificationShort) {
    this.notificationService.deleteNotification(notification.id).subscribe(
      (res) => {
        this.onSearch();
        this.toast.success('Deleted notification successfully!');
      },
      (err: string) => {
        this.errorFn(err);
      }
    );
  }

  getNotificationUnRead(tabType: NotificationTab) {
    this.searchRequest.page = 0;
    this.activeTab = tabType;

    if (NotificationTab.unread === tabType) {
      this.searchRequest.read = false;
    } else {
      this.searchRequest.read = null;
    }

    this.onSearch();
  }

  onMarkAllNotification() {
    if (!this.loggedUser || this.numberNotification < 1) {
      return;
    }
    this.notificationService.markAllAsRead(this.loggedUser.id).subscribe(
      () => {
        this.countUnreadNotifications();

        if (this.activeTab === NotificationTab.unread) {
          this.rows = [];
          return;
        }

        this.rows?.forEach(row => row.read = true);
      });
  }

  onMarkNotification(notification: NotificationShort) {
    if (!notification) {
      return;
    }
    const payload: NotificationRequest = {
      field: NotificationPatchType.read,
      value: true
    };

    this.notificationService.markAsRead(notification.id, payload).subscribe(
      (res) => {
        if (res) {
          const index = this.rows?.findIndex(r => r.id === res.id);
          this.rows[index].read = true;
        }
        this.countUnreadNotifications();
      }
    );
  }

  onScrollDown() {
    this.addNotifications();
  }

  addNotifications() {
    if (this.searchRequest.page < this.pageInfo.totalPages) {
      this.searchRequest.page++;
    }

    this.onSearch((noties) => {
      if (this.searchRequest.page === this.pageInfo.totalPages) {
        this.searchRequest.page = 0;
        return;
      }
      this.rows = this.rows?.concat(noties);
    });
  }

  showMoreThanLimitNotifications() {
    return this.numberNotification > this.limitNotificationDisplay;
  }
}
