import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateComponent } from './date.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LibsModule } from 'src/app/libs/libs.module';
import { XPipesModule } from 'src/@xcorp/pipes/x-pipes.module';

@NgModule({
  declarations: [
    DateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LibsModule,
    ReactiveFormsModule,
    XPipesModule,
  ],
  exports: [
    DateComponent
  ]
})
export class DateModule { }
