export const QUERY_PARAMS = {
  task: {
    id: 'id',
    selectedTask: 'selectedTask',
    view: 'view',
  },
  comment: {
    comment: 'comment',
    replyComment: 'replyComment',
  },
  okr: {
    selectedObjective: 'selectedObjective',
    selectedKeyresult: 'selectedKeyResult',
    selectedTask: 'selectedOkrTask',
  },
}

export enum QUERY_PARAMS_VALUE {
  detail = 'detail',
  modal = 'modal',
};
