import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import { XDirectivesModule } from 'src/@xcorp/directives/x-directives.module';
import { XPipesModule } from 'src/@xcorp/pipes/x-pipes.module';

@NgModule({
  declarations: [
    AvatarComponent,
  ],
  imports: [
    CommonModule,
    XDirectivesModule,
    XPipesModule,
  ],
  exports: [
    AvatarComponent,
  ]
})
export class AvatarModule { }
