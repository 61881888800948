import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function usernameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    if (!value) return null;

    // Regex for allowed characters and at least one alphabet character
    const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9._-]+$/;
    const valid = regex.test(value);

    return valid ? null : { invalidUsername: true };
  };
}
