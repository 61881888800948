import {
  ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input,
  Output, TemplateRef, ViewChild, ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { Subject, Subscription, Observable, switchMap, of, debounceTime, sampleTime, combineLatest, iif } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { UtilService } from 'src/app/core';
import {
  AbstractComponent, ActiveBoard, BoardStatus, DATE_TIME_UI_12_HOURS_FORMAT, DATE_UI_FORMAT, Field,
  permissionCode, Project, ProjectMemberUser, ProjectPlatform, ProjectType, routerObject, Task,
  TaskAttachment, TaskCloneRequest, TaskLinkGroupBy, TaskPatch, TaskTypeEnum, UNSAVED_CACHES,
  UNSAVED_TASK_KEY, UNSAVED_USER_KEY, UnsavedCache, WorkItemTypeEnum, StatusSearchParams,
  TASK_NAME_MAX_LENGTH_MESSAGE, TaskPatchType, projectRouteParam, DATE_FORMAT, User, DynamicFieldHash,
  COMPLETE_PROGRESS, TaskContributor
} from 'src/app/shared';
import { EditorFormPayload } from 'src/app/shared/_components/editor-form/editor-form.component';
import { EditorUserMention } from 'src/app/shared/_components/editor/editor.component';
import { BoardStoryline } from 'src/app/shared/_models/board-storyline.model';
import { BacklogDataService } from '../../projects/backlogs/_service/backlog-data-service';
import { EpicDataService } from '../../projects/epics/_service/epic-data-service';
import { ProjectsService } from '../../projects/_services';
import { ProjectBoardStatusService } from '../../projects/_services/project-board-status.service';
import { PermissionCheckService } from '../../role-permission/_services/permission-check.service';

import { NavbarService } from '../../_components/navbar/_services/navbar.service';
import { SiteManagementState } from '../../_store/site-management';
import { TaskService } from '../_services/task.service';
import { TaskDetailFileComponent } from './task-detail-file/task-detail-file.component';
import { TaskDetailLinkIssueComponent } from './task-detail-link-issue/task-detail-link-issue.component';
import { TaskDetailSubtaskComponent } from './task-detail-subtask/task-detail-subtask.component';
import { TaskDetailTimesheetComponent } from './task-detail-timesheet/task-detail-timesheet.component';
import { TaskUpsertComponent } from 'src/app/site-management/tasks/task-upsert/task-upsert.component';
import { BacklogService } from '../../projects/backlogs/_service/backlog.service';
import { DynamicFieldDataService } from '../../dynamic-field/_services/dynamic-field-data.service';
import { FieldService } from '../../dynamic-field/_services/field-service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TaskReminderService } from '../../reminder/_services/task-reminder.service';
import { BacklogContextMenuDataService } from '../../projects/backlogs/backlog-context-menu/_service/backlog-context-menu.service';
import { TranslateService } from '@ngx-translate/core';
import { GeneralConfirmComponent } from '../../_components';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { ProjectActions, selectStatuses } from '../../_store/project';
import { EpicClonePayload } from '../../projects/epics/model';
import { EpicService } from '../../projects/epics/_service/epic.service';
import { TaskActions } from '../../_store/task';
import { selectEditorMode } from 'src/app/core/store/app';
import { EditorMode } from 'src/app/shared/_components/x-editor/x-editor';
import { TaskPermissionChecker } from '../_services/task-permission.service';
import { TaskDateComponent } from 'src/app/shared/_components/task-date/task-date.component';
import { getTaskPermissionSelector } from '../../_store/permission/permission.utils';
import { isNormalTask } from '../../_store/task/task.utils';
import { DynamicFieldActions, ProjectsTaskDFields } from '../../_store/dynamic-field';
import { cloneDeep } from 'lodash';
import { TaskDetailDataService } from './services/task-detail-data.service';
import { TaskDetailContributorUpsertComponent } from './task-detail-contributor/task-detail-contributor-upsert/task-detail-contributor-upsert.component';
import { Dialog } from '@angular/cdk/dialog';
import { TaskDetailContributorComponent } from './task-detail-contributor/task-detail-contributor.component';
import { TaskContributorStore } from './task-detail-contributor/task-contributor.store';

export enum HeadingTabs {
  General,
  Detail,
  Task,
}

export enum ActionTabs {
  COMMENT,
  CHECK_IN,
  HISTORY,
  COMMIT,
}

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TaskContributorStore],
})
export class TaskDetailComponent extends AbstractComponent {
  @Input() task: Task;
  @Input() taskId: number;
  @Input() linkable = false;
  @Input() uiDirection: 'row' | 'column' = 'row';
  @Input() isModal = false;
  @Input() closeable = false;
  @Input() canOpenTaskKeyInNewTab = true;
  @Input() showTaskTab = false;
  @Input() isEpic = false;
  @Input() okrContainer: {
    checkInTabTmp?: TemplateRef<any>,
    moreOptionTemp?: TemplateRef<any>,
  };
  @Input() isSplitArea = false;

  @Output() hide = new EventEmitter<any>();
  @Output() updated = new EventEmitter<Task>();
  @Output() subtaskAdded = new EventEmitter<Task>();
  @Output() taskKeyClicked = new EventEmitter<Task>();
  @Output() clickTask = new EventEmitter<Task>();
  @Output() taskDeleted = new EventEmitter<Task>();
  @Output() clonedTask = new EventEmitter<Task>();
  @Output() onPatchField = new EventEmitter<TaskPatch>();

  @ViewChild('breadcrumb') breadcrumb: TemplateRef<any>;
  @ViewChild('timesheet') timesheetComponent: TaskDetailTimesheetComponent;
  @ViewChild('detailFile') taskDetailFileComponent: TaskDetailFileComponent;
  @ViewChild('linkIssue') taskDetailLinkIssueComponent: TaskDetailLinkIssueComponent;
  @ViewChild('subtasks') taskDetailSubtaskComponent: TaskDetailSubtaskComponent;
  @ViewChild('taskContributor') taskContributorComponent: TaskDetailContributorComponent;
  @ViewChild('filesElement') filesElement: ElementRef;
  @ViewChild('epicTasksElement') epicTasksElement: ElementRef;
  @ViewChild('subtasksElement') subtasksElement: ElementRef;
  @ViewChild('linkIssueElement') linkIssueElement: ElementRef;
  @ViewChild('timesheetElement') timesheetElement: ElementRef;
  @ViewChild('taskForm') taskFormElement: ElementRef<HTMLElement>;
  @ViewChild('tabset', { static: false }) staticTab?: TabsetComponent;
  @ViewChild('cloneTasksTemplate') cloneTasksTemplate: TemplateRef<any>;

  showTaskLinkCopy = false;
  isDragging = false;
  form: UntypedFormGroup;
  status$: Observable<BoardStatus[]>;
  members: ProjectMemberUser[] = [];
  taskSubscription = {};
  activeBoard: ActiveBoard;
  currentProject: Project;
  taskEpic: Task;
  loadEpicSubscription: Subscription;
  taskKey: string;
  TaskTypeEnum = TaskTypeEnum;
  fields: Field[] = [];
  fields$ = new Subject<Field[]>();
  showOption = false;
  isShowTaskDetail = false;
  selectedTaskEpic: Task;
  localDescription: string = null;
  cloneAllTasksControl = new UntypedFormControl(false);
  DATE_TIME_FORMAT = DATE_TIME_UI_12_HOURS_FORMAT;
  TASK_NAME_MAX_LENGTH_MESSAGE = TASK_NAME_MAX_LENGTH_MESSAGE;

  isTaskDetailPage = false;
  attachments: TaskAttachment[] = [];
  activeTab = ActionTabs.COMMENT;
  ActionTabs = ActionTabs;

  taskQueue: { payload: TaskPatch, callback: ()=> void }[] = [];
  isCallingApi = false;

  modalsRef: BsModalRef[] = [];
  ProjectType = ProjectType;
  editorMode: EditorMode;

  permissionChecker: TaskPermissionChecker;
  loggedUser!: User;
  canCreate = true;
  canDelete = true;
  COMPLETE_PROGRESS = COMPLETE_PROGRESS;
  contributors: TaskContributor[] = [];
  resizeObserver!: ResizeObserver;
  resize$ = new Subject<number>();

  get isAssigned() {
    return this.authService?.getLoggedUserRole()?.isSuperAdmin ||
      this.task.assignee?.id === this.loggedUser.id ||
      this.task.reporter?.id === this.loggedUser.id ||
      this.task.cc?.find(_cc => _cc?.id === this.loggedUser.id) ||
      this.task.createdBy?.id === this.loggedUser.id;
  }

  constructor(
    private store: Store<SiteManagementState>,
    private backlogDataService: BacklogDataService,
    private backlogService: BacklogService,
    private epicDataService: EpicDataService,
    private navbarService: NavbarService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private projectBoardStatusService: ProjectBoardStatusService,
    private taskService: TaskService,
    private projectService: ProjectsService,
    private clipboardService: ClipboardService,
    private permissionCheckService: PermissionCheckService,
    private modalService: BsModalService,
    private fieldService: FieldService,
    private dynamicFieldDataService: DynamicFieldDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private taskReminderService: TaskReminderService,
    private translateService: TranslateService,
    private backlogContextMenuDataService: BacklogContextMenuDataService,
    private authService: AuthService,
    public epicService: EpicService,
    private storeGlobal: Store,
    private _taskDetailData: TaskDetailDataService,
    private _dialog: Dialog,
    private _contributorStore: TaskContributorStore,
  ) {
    super();
    this.checkWindowSize();
    this.getAllFields();
    this.loggedUser = this.authService.getLoggedUser();
  }

  init(): void {
    this.isTaskDetailPage = this.route.snapshot.data['isTaskDetailPage'];
    this.createForm();
    this.observeFormEvent();
    this.listenObserveStore();
    this.observerModal();
    this.observerEditorMode();

    if (!this.task && !this.taskId) {
      this.taskKey = this.taskKey ?? this.route?.snapshot?.paramMap?.get('id');
      this.getTaskDetailByTaskKey(this.taskKey);
      return;
    }

    if (!this.task && this.taskId) {
      this.getTaskDetail();
    } else {
      this.taskId = this.task.id;
      this.updateTaskData(this.task);
      this.getLocalDescription();
    }
  }

  destroy(): void {
    this.resizeObserver.unobserve(this.taskFormElement.nativeElement);
    this.resize$.complete();
  }

  observerEditorMode() {
    this.storeGlobal.select(selectEditorMode)
    .pipe(
      tap((mode) => {
        if (mode === 'edit') {
          this.editorMode = mode;
        }
      }),
      takeUntil(this.destroyed$),
      sampleTime(500)
    )
    .subscribe(mode => {
      this.editorMode = mode;
    })
  }

  observeElementResizing() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          this.resize$.next(entry.contentRect.width);
        }
      }
    });

    this.resize$.subscribe(width => {
      this._taskDetailData.currentWidth$.next(width);
    });

    this.resizeObserver.observe(this.taskFormElement.nativeElement);
  }

  observeTaskPermission() {
    combineLatest([
      this.store.select(getTaskPermissionSelector(this.task)),
      this.fields$
    ]).pipe(takeUntil(this.destroyed$))
      .subscribe(([permission, fields]) => {
        if (!fields?.length) {
          return;
        }
        this.permissionChecker = permission;
        this.checkFormFieldsPermission();
        this.checkUpsertPermission();
      });
  }

  checkUpsertPermission() {
    const { canCreate, canEdit, canDelete } = this.permissionChecker;
    this.canCreate = canCreate;
    this.canDelete = canDelete;
    
    if (!isNormalTask(this.task)) {
      this.canCreate = canEdit && this.canCreate;
      this.canDelete = canEdit && this.canDelete;
    }
  }

  checkFormFieldsPermission() {
    if (this.permissionChecker.canEditAnother) {
      return;
    }

    if (!this.task?.isEditable) {
      this.disableAllControl();

      if (!isNormalTask(this.task)) {
        return;
      }
    }

    if (!this.isAssigned && !this.task?.isEditable && !this.permissionChecker?.canEditAnother) {
      return;
    }

    this.checkPermissionOnPrivateFlgField();
    this.checkPermissionOnSpecificFields();
    this.checkPermissionOnAdvanceFields();
    this.checkPermissionAfterDueDate();
  }

  getLocalDescription() {
    const unsavedCache = JSON.parse(localStorage.getItem(UNSAVED_CACHES));
    const userKey = UNSAVED_USER_KEY + this.authService.getLoggedUser()?.id;
    const taskKey = UNSAVED_TASK_KEY + this.task.key;
    this.localDescription = (unsavedCache as UnsavedCache)?.[userKey]?.[taskKey]?.description;
  }

  // updateLocationUrl(taskKey: string = '') {
  //   if (this.isModal) {
  //     const queryParams: Params = {};
  //     queryParams[taskQueryParams.modalTask] = taskKey || '';

  //     this.router.navigate(
  //       [location.pathname],
  //       { queryParams, queryParamsHandling: 'merge' }
  //     );
  //   }
  // }

  afterViewInit(): void {
    if (this.uiDirection === 'row' && !this.isModal) {
      this.navbarService.changeTemplate(this.breadcrumb);
    }
    this.observeElementResizing();
  }

  ngAfterViewChecked(): void {
    if (this.showTaskTab && this.staticTab) {
      this.staticTab.tabs[HeadingTabs.Task].active = true;
      this.showTaskTab = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  // canEditSprintRelease() {
  //   return this.permissionCheckService.hasProjectPermission(permissionCode.TASK_MANAGEMENT_BACKLOG_EDIT);
  // }

  createForm() {
    this.form = this.fb.group({
      name: [null, [
        Validators.required, Validators.maxLength(512)
      ]],
      description: [null],
      status: [null],
      dueDate: [null],
      startDate: [null],
      endDate: [null],
      startTime: [null],
      endTime: [null],
      assignee: [null],
      reporter: [null],
      ccs: [null],
      type: [null, [
        Validators.required,
      ]],
      priority: [null],
      epic: [null],
      colorCode: [null],
      timeOriginalEstimate: [null],
      storyPoint: [null],
      labels: [null],
      versions: [null],
      sprint: [null],
      privateFlg: [null],
      followUp: [null],
      template: [null],
      taskTemplate: [],
      platform: [null],
      progress: [0, [Validators.min(0), Validators.max(1000)]], // maximum 1000%
      storylineEstimates: [[]],
      storyline: [null],
      metric: [null],
      startMetricValue: [null],
      metricValue: [null],
      currentMetricValue: [null],
      taskRes: [null],
      reminder: [null],
    });
  }

  checkPermissionOnPrivateFlgField() {
    const privateFlagCtrl = this.form.get('privateFlg');
    const canEdit = this.permissionChecker.canEditPrivateFlag;
    canEdit ? privateFlagCtrl.enable({ emitEvent: false }) : privateFlagCtrl.disable({ emitEvent: false });
  }

  checkPermissionOnSpecificFields() {
    const controls = [
      { control: this.form.get('assignee'), canEdit: this.permissionChecker.canEditAssignee },
      { control: this.form.get('reporter'), canEdit: this.permissionChecker.canEditReporter },
      { control: this.form.get('ccs'), canEdit: this.permissionChecker.canEditCC },
    ];
    
    controls.forEach(({ control, canEdit }) => {
      canEdit ? control.enable({ emitEvent: false }) : control.disable({ emitEvent: false });
    });
  }

  checkPermissionOnAdvanceFields() {
    const { canEdit } = this.permissionChecker.checkAdvanceField();

    this.fields.forEach(field => {
      if (!field.isAdvanced || !field?.isVisible) {
        return;
      }

      // Need to hard code to prevent impact (by duc.le)
      const fieldKey = field.variable === 'release' ? 'versions' : this.convertCamelCase(field.variable);
      const ctrl = this.form?.get(fieldKey);
      field.disabled = !canEdit;
      canEdit ? ctrl?.enable({ emitEvent: false }) : ctrl?.disable({ emitEvent: false });
    });
  }

  convertCamelCase(str: string) {
    return str
      .toLowerCase()
      .split('_')
      .map((word, index) =>
        index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join('');
  }

  checkPermissionAfterDueDate() {
    if (!this.permissionChecker.canEditAfterDueDate(this.task?.dueDate)) {
      this.disableAllControl();
    }
  }

  disableAllControl() {
    this.form.disable({ emitEvent: false });
    this.fields.forEach(field => {
      field.disabled = true;
    });
  }

  observeFormEvent() {
    this.getAllFields();

    this.form.get('status').valueChanges.subscribe((status) => {
      this.patchValue({
        type: 'statusId',
        value: status ? `${status.id}` : null,
      });
    });

    this.backlogDataService.taskChange$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          // this.taskService.getSubtasks(this.task?.id).subscribe((subTasks) => {
          //   const sumStoryPoint = this.sumStoryPointOfSubtask(subTasks);
          //   if (sumStoryPoint) {
          //     this.task.storyPoint = sumStoryPoint;
          //     this.updateFormData(this.task);
          //   }
          // });
          if (value.currentValue?.parentTask?.id === this.task.id) {
            this.task.storyPoint = value.currentValue?.parentTask.storyPoint;
            this.updateFormData(this.task);
          }
          if (value.previousValue && value.previousValue.id === this.task?.id) {
            Object.assign(this.task, value.currentValue);
            this.updateFormData(this.task);
          }
        });

    this._contributorStore.contributors$.subscribe(data => {
      if (this.contributors?.length && this.contributors !== data) {
        this.getTaskDetail();
      }
      this.contributors = data;
    });
  }

  listenObserveStore() {
    this.status$ = this.store.select(selectStatuses);
  }

  sumStoryPointOfSubtask(subTasks: Task[]) {
    return subTasks?.reduce((pre, cur) => {
      return pre += cur.storyPoint;
    }, 0);
  }

  updateFormData(task: Task) {
    const storyline: BoardStoryline = this.form.get('storyline').value || null;
    const platform: ProjectPlatform = this.form.get('platform').value || null;
    this.form.get('taskRes').setValue(task);
    this.form.patchValue({
      name: task.name,
      description: task.description,
      status: task.status,
      dueDate: task.dueDate ? UtilService.getDateFormat(new Date(task.dueDate), DATE_UI_FORMAT) : null,
      startDate: task.startDate ? UtilService.getDateFormat(new Date(task.startDate), DATE_UI_FORMAT) : null,
      endDate: task.endDate ? UtilService.getDateFormat(new Date(task.endDate), DATE_UI_FORMAT) : null,
      startTime: task.startTime ? UtilService.convertFullTimeTo12Hours(task.startTime) : null,
      endTime: task.endTime ? UtilService.convertFullTimeTo12Hours(task.endTime) : null,
      assignee: task.assignee,
      reporter: task.reporter,
      ccs: task.cc,
      type: task.type,
      priority: task.priority,
      epic: task.epic,
      colorCode: task.colorCode,
      timeOriginalEstimate: task.timeOriginalEstimate,
      storyPoint: task.storyPoint,
      labels: task.labels,
      versions: task.versions,
      sprint: task.sprint,
      privateFlg: task.privateFlg,
      followUp: task.followUp,
      template: task.template,
      taskTemplate: task.taskTemplate,
      platform: task.platform,
      progress: task.progress || 0,
      metric: task.metric,
      metricValue: task.metricValue,
      startMetricValue: task.startMetricValue,
      currentMetricValue: task.currentMetricValue,
      storylineEstimates: task.storylineEstimates || [],
    }, { emitEvent: false });

    if (platform?.id !== task.platformId) {
      this.form.get('platform').setValue(platform, { emitEvent: false });
    }

    if (task.storylineId && storyline?.id !== task.storylineId) {
      this.taskService.getById(task.storylineId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (storylineRes) => {
          this.form.get('storyline').setValue(storylineRes, { emitEvent: false });
        },
        (error: string) => { }
      );
    }
  }

  onProjectChange(project: Project) {
    if (!this.currentProject || this.currentProject?.id === project?.id) {
      return;
    }

    this.router.navigate([this.routerObject.taskMgmtBacklog.fullPath.replace(projectRouteParam, project.key)]);

    // if (this.uiDirection === 'row' && !this.isModal) {
    //   this.getActiveBoard();
    // }
  }

  getActiveBoard() {
    this.projectService.getActiveBoard(this.currentProject?.id)
      .subscribe(
        (activeBoard) => {
          this.activeBoard = activeBoard;
        },
        err => { }
      );
  }

  getProjectMembers() {
    this.projectService.getProjectMembers(this.task.project?.id)
      .subscribe(
        (members) => {
          this.members = members;
        },
        (error) => {
          this.members = [];
        }
      );
  }

  getUserPromise = (keyword: string): Promise<EditorUserMention[]>  => {
    return this.projectService.getProjectMembers(this.task.project?.id, keyword)
    .pipe(
      takeUntil(this.destroyed$),
      map(members => {
        return members?.map(m => ({
          id: m.id,
          value: m.fullName,
          avatar: m.avatar,
        })) ?? [];
      })
    )
    .toPromise();
  }

  uploadFileDescription$ = (file: File) => {
    return this.taskService.addEditorAttachment(this.task.id, file, 'Description');
  }

  uploadFileComment$ = (file: File) => {
    return this.taskService.addEditorAttachment(this.task.id, file, 'Comment');
  }

  getTaskDetail() {
    this.taskService.getById(this.taskId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (task) => {
          this.updateTaskData(task);
          this.getLocalDescription();
        },
        (error: string) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        }
      );
  }

  getTaskDetailByTaskKey(taskKey: string) {
    this.taskService.getByTaskKey(taskKey)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (task) => {
          this.updateTaskData(task);
          this.getAllFields();
        },
        (error: string) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
          this.hideModal();
        }
      );
  }

  private getTaskEpic() {
    if (this.loadEpicSubscription) {
      this.loadEpicSubscription.unsubscribe();
    }
    this.taskEpic = null;
    if (this.task.epic && this.task.type?.name !== WorkItemTypeEnum.EPIC) {
      this.loadEpicSubscription = this.taskService.getByKey(this.task.epic.id).subscribe(taskEpic => {
        this.taskEpic = { ...taskEpic };
      });
    }
  }

  updateTaskData(task: Task) {
    this.task = task;
    this.taskId = this.task.id;
    this.checkCurrentTask();
    if (!this.currentProject) {
      this.currentProject = task.project;
      this.onProjectChange(task.project);
      if (this.uiDirection === 'row' && !this.isModal) {
        this.getActiveBoard();
      }
    }
    this.getLocalDescription();
    this.updateFormData(task);
    this.getStatus();
    this.getProjectMembers();
    this.getTaskEpic();
    this.observeTaskPermission();
  }

  isStorylineTask() {
    return this.task && this.task.type?.name === TaskTypeEnum.Storyline;
  }

  isEpicTask() {
    return this.task && this.task.type?.name === TaskTypeEnum.Epic;
  }

  isTaskTemplate() {
    return this.task && this.task.type?.name === TaskTypeEnum.TaskTemplate;
  }

  isStorylinePlatFormTask() {
    return this.task && this.task.storylineId && this.task.type?.name === TaskTypeEnum.Story;
  }

  checkCurrentTask() {
    if (!this.task) {
      return;
    }

    if (this.isStorylineTask()) {
      this.form.get('type').disable();
      this.form.get('storyPoint').disable();
      return;
    }

    if (this.isEpicTask() || this.isTaskTemplate()) {
      this.form.get('type').disable();
      return;
    }
  }

  onEnterName(event, element: HTMLElement) {
    event.preventDefault();
    event.stopPropagation();
    element?.blur();
    this.nameFocusout();
  }

  nameFocusout() {
    const nameCtrl = this.form.get('name');
    if (nameCtrl.invalid) {
      return;
    }

    const name = nameCtrl.value;

    if (name !== this.task.name) {
      this.patchValue({
        type: 'name',
        value: name ? `${name}` : null,
      });
    }
  }

  descriptionFocusout(event: { payload: EditorFormPayload, callback: () => void }) {
    if (event.payload.htmlString !== this.task.description) {
      const value = event.payload.htmlString ? `${event.payload.htmlString}` : null;
      this.patchValue({
        type: 'description',
        value,
      }, () => {
        this.mentionUsers(event.payload.selectedUserMention, value);
        this.localDescription = null;
        event.callback();
      });
    }
  }

  onCancelDescription() {
    this.localDescription = null;
  }

  patchValue(payload: TaskPatch, callback?: () => void) {
    this.taskQueue.push({ payload , callback });
    if (!this.isCallingApi) {
      this.callNextTask();
    }
  }

  callNextTask() {
    if (this.taskQueue.length > 0) {
      this.isCallingApi = true;
      const nextTask = this.taskQueue.shift();
      this.updateValue(nextTask.payload, (task) => {
        this.isCallingApi = false;
        if (task) {
          nextTask.callback?.();
        }
        if (!this.taskQueue.length) {
          this.backlogDataService.emitTaskChange({
            previousValue: this.task,
            currentValue: task,
          });
          this.updated.next(task);
        }
        this.callNextTask();
      });
    }
  }

  updateValue(payload: TaskPatch, callback?: (task?) => void) {
    if (this.taskSubscription[payload.type]) {
      this.taskSubscription[payload.type].unsubscribe();
    }

    this.taskSubscription[payload.type] = this.taskService.patch(this.task.id, payload)
      .pipe(
        // takeUntil(this.destroyed$),  // Waiting when the modal was hidden
        switchMap((task) => {
          if (payload.type === TaskPatchType.description && this.currentProject?.linkedJira) {
            return this.taskService.putJiraDesc(task.id, task.description);
          }
          return of(task)
        }))
      .subscribe(
        (task) => {
          callback?.(task);
          this.onPatchField.next(payload);
        },
        (error: string) => {
          this.updateFormData(this.task);
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
          callback?.();
        }
      );
  }

  getStatus(keyword: string = '') {
    const params = {...new StatusSearchParams(), keyword};
    this.store.dispatch(ProjectActions.getStatuses({projectId: this.task.project.id, params}));
  }

  saveComment(users: EditorUserMention[] = [], content: string) {
    this.backlogDataService.emitTaskChange({
      previousValue: this.task,
      currentValue: this.task,
    });
    this.mentionUsers(users, content);
  }

  mentionUsers(users: EditorUserMention[] = [], content: string) {
    if (users.length <= 0) {
      return;
    }
    const userIds = users.map(e => e.id);
    this.taskService.mentionUsers(this.task?.id, { userIds, content })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => { },
        (error: string) => {
          const httpError = JSON.parse(error);
          this.toast.error(httpError?.message);
        }
      );
  }


  getDateTimeUI(date: string) {
    return UtilService.getDateUI(date, DATE_TIME_UI_12_HOURS_FORMAT);
  }

  logWork() {
    this.timesheetComponent?.onAdd();
    // this.timesheetElement?.nativeElement?.scrollIntoView({behavior: 'smooth'});
  }

  addReminder() {
    this.taskReminderService.onShowCustomReminder(this.task);
  }

  addAttachment() {
    this.taskDetailFileComponent?.onAdd();
    // this.filesElement?.nativeElement?.scrollIntoView({behavior: 'smooth'});
  }

  addSubtask() {
    this.taskDetailSubtaskComponent?.onAdd();
    // this.subtasksElement?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
  }

  addLink() {
    this.taskDetailLinkIssueComponent?.onAdd();
    // this.linkIssueElement?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
  }

  onSubtaskAdded(task: Task) {
    this.subtaskAdded.emit(task);
  }

  onFileChanged(fileList: FileList) {
    this.taskDetailFileComponent.onFileChanged(fileList);
  }

  openTask(task: Task) {
    if (!task || !task?.key) {
      return;
    }

    if (this.canOpenTaskKeyInNewTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/task-mgmt/tasks/${task.key}`])
      );

      window.open(url, '_blank');
    } else {
      this.taskKeyClicked.emit(task);
    }
  }

  onCreateTask() {
    const modalRef = this.modalService.show(TaskUpsertComponent, {
      backdrop: 'static',
      class: 'modal-md',
      initialState: {
        task: null,
        selectedProjectId: this.currentProject?.id,
        selectedSprintId: null,
      }
    });

    modalRef.content.formSubmit.subscribe((res: Task) => {
      if (res) {
        const ids = [res?.id];
        this.backlogService.addTasksToBacklogs({taskIds: ids});
      }

      modalRef.hide();
    });
  }

  hideModal() {
    this.hide.next(null);
  }

  onNewTaskAddedToEpic(data: { previousValue: Task, currentValue: Task }) {
    if (data && data.currentValue) {
      // impact: reload epics page when create task in epic
      // this.epicDataService.emitTaskChange({
      //   previousValue: data.previousValue,
      //   currentValue: data.currentValue,
      // });
    }
  }

  onClickTask(task: Task) {
    this.clickTask.emit(task);
    if(this.task.type?.name === TaskTypeEnum.Epic && this.uiDirection === 'row') {
      this.selectedTaskEpic = null;

      setTimeout(() => {
        this.selectedTaskEpic = task;
      }, 0);
      this.isShowTaskDetail = true;
    }
  }

  onTaskEpicUpdated(task: Task) {
    Object.assign(this.selectedTaskEpic, task);
  }

  onDeleteClick() {
    if (this.task.type?.name === WorkItemTypeEnum.EPIC) {
      this.epicDataService.emitEpicDelete(this.task.id);
    }
  }

  hideCreateSubTask(type: string): boolean {
    return [TaskTypeEnum.Epic, TaskTypeEnum.SubTask, TaskTypeEnum.Storyline].some(e => e === type);
  }

  hideLinkIssue(type: string): boolean {
    return [TaskTypeEnum.Epic].some(e => e === type);
  }

  hideAddContributor(task: Task) {
    return task?.project?.multiAssigneeFlg && task?.type?.name !== TaskTypeEnum.SubTask;
  }

  onTaskKeyMouseOver() {
    this.showTaskLinkCopy = true;
  }
  onTaskKeyMouseLeave() {
    this.showTaskLinkCopy = false;
  }
  onCopyLinkToClipboard() {
    this.clipboardService.copy(UtilService.getTaskUrl(this.task.key));
    this.toast.success('Copied successfully!');
  }

  onUpdateDueDate(date) {
    this.patchValue({
      type: TaskPatchType.dueDate,
      value: UtilService.getDateUI(date, DATE_FORMAT),
    });
  }

  // Dragleave listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const quillEditorElement = this.taskFormElement?.nativeElement?.querySelector('.preventUpload');
    this.isDragging = quillEditorElement ? false : true;
  }

  // Dynamic field
  getAllFields() {
    if (!this.taskId) {
      return;
    }
    const tab = this.dynamicFieldDataService.getDynamicFieldByHash(DynamicFieldHash.PAGE_TASK_TABS);
    const section = this.dynamicFieldDataService.getDynamicFieldByHash(DynamicFieldHash.PAGE_TASK_SECTIONS);
    this.fieldService.getAllByDFieldId(tab?.id, this.taskId as any)
      .subscribe(fields => {
        this.fields = fields.filter(e => e.sectionId === section?.id).map(e => {
          return {
            ...e,
            template: this[e.hash],
          };
        });
        this.fields$.next(fields);
        const taskDFields: ProjectsTaskDFields = {
          projectId: this.task.project.id,
          fields: cloneDeep(this.fields)
        }
        this.store.dispatch(DynamicFieldActions.addTaskDField({ field: taskDFields }))
      });
  }

  onDeleteTask() {
    const initialState = {
      title: `deleteTask`,
      titleParams: { key: this.task?.key },
      message: 'confirmDeleteTask',
    };
    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, { backdrop: 'static', initialState });

    confirmModalRef.content.result$.subscribe((result) => {
      if (result) {
        this.taskService.deleteTask(this.taskId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            task => {
              this.taskDeleted.next(task);
              this.hideModal();
              this.toast.success('Delete Task Successfully!');
              if (this.isTaskDetailPage) {
                this.router.navigate([this.routerObject.taskMgmtTaskList.fullPath]);
              }
            },
            error => this.errorFn(error)
          );
      }
    });
  }

  onCloneTask() {
    const payload: TaskCloneRequest = { jobTitleIds: [], taskIds: [this.task.id], cloneSubTask: true };
    this.taskService
      .cloneTasks(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (task) => {
          const clonedTask = task.tasks?.[0];
          this.clonedTask.next(clonedTask);
          this.store.dispatch(TaskActions.createTaskSuccess({ task: clonedTask }));

          const taskLinkGroupBy: TaskLinkGroupBy = {
            type: clonedTask.type as any,
            linkedTasks: [clonedTask as any]
          };
          this.task.sortLinkedTasks = [
            ...this.task.sortLinkedTasks,
            taskLinkGroupBy
          ];
        },
        (error) => this.errorFn(error)
      );
  }

  onCloneEpics() {
    const initialState = {
      title: 'Clone Epic',
      message: 'Do you want to clone this epic?',
      params: { size: 1 },
      templateRef: this.cloneTasksTemplate,
    };
    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, { backdrop: 'static', initialState });

    confirmModalRef.content.result$.subscribe((result) => {
      if (result) {
        const payload: EpicClonePayload = {
          epicIds: [this.task.id],
          cloneTasks: this.cloneAllTasksControl.value,
          cloneSubTasks: this.cloneAllTasksControl.value,
          projectId: this.currentProject?.id
        }
        this.epicService.cloneEpics(payload)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((res) => {
            const id = res.epicIds?.[0];
            if (id) {
              this.taskService.getById(id)
              .pipe(takeUntil(this.destroyed$))
              .subscribe(
                (task) => {
                  this.clonedTask.next(task);
                  this.store.dispatch(TaskActions.createTaskSuccess({ task }));
                },
                (error: string) => {
                  this.toast.success('Created Successfully!');
                }
              );
            }

          })
      }
    });
  }

  uploadAttachmentSuccess(isSuccess: boolean) {
    if (isSuccess) {
      this.taskService
        .getAttachments(this.task.id)
        .pipe(takeUntil(this.destroyed$), debounceTime(500))
        .subscribe((attachments) => {
          this.attachments = attachments;
        });
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  closeOverlay(event: KeyboardEvent) {
    if (this.editorMode === 'edit') {
      return;
    }

    if (this.isModal) {
      event.preventDefault();
      event.stopPropagation();
      const lastModal = this.modalsRef.pop();
      if (lastModal?.id) {
        this.modalService.hide(lastModal.id);
      }
    }

    if (this.modalsRef.length) {
      return;
    }

    this.hide.emit();
  }


  observerModal() {
    this.modalService.onShown.pipe(takeUntil(this.destroyed$))
      .subscribe(ref => {
        this.modalsRef.push(ref);
    });
    this.modalService.onHide.pipe(takeUntil(this.destroyed$))
      .subscribe(ref => {
        this.modalsRef = this.modalsRef.filter(e=>e.id !== ref.id);
    });
  }

  onSelectTab(tab) {
    this.activeTab = tab;
  }

  onClickDueDate(control: TaskDateComponent) {
    if (this.form.get('dueDate').disabled) {
      return;
    }
    control?.input?.nativeElement.click();
  }

  addContributor() {
    const initialState = {
      task: this.task,
      contributors: this.contributors,
    };

    const modalRef = this._dialog.open(TaskDetailContributorUpsertComponent);
    const component = modalRef.componentInstance;
    Object.assign(component, initialState);
    component.payloadResponse.subscribe(payload => {
      if (payload) {
        this._contributorStore.addContributor({ payload });
      }
      modalRef.close();
    });
  }
}
