<button type="button"
        #button
        *ngIf="!buttonRef"
        class="w-[32px] h-[32px] rounded-sm inline-flex items-center justify-center text-xl text-[var(--x-secondary)]  bg-[var(--x-light-color)] hover:bg-[var(--x-btn-hover)]"
        [ngClass]="{
          'w-auto px-0': !setBtnWidth || showArrow,
          'h-auto': !setBtnHeight,
          'pl-1': showArrow,
          'x-border': border,
          'bg-none': noBackground
        }"
        [matTooltip]="tooltip | translate"
        (click)="onClick()"
        stopEvent>
  <span *ngIf="iconType === type.MAT" class="material-icons-outlined"> {{ icon }} </span>
  <mat-icon *ngIf="iconType === type.LOCAL" [svgIcon]="icon"></mat-icon>
  <span *ngIf="showArrow" class="material-icons-outlined">
    arrow_drop_down
  </span>
</button>

<div
  #button
  *ngIf="buttonRef"
  class="inline-flex items-center justify-center pointer"
  (click)="onClick()"
>
  <ng-container *ngTemplateOutlet="buttonRef"></ng-container>
</div>

<ng-template #dropdown>
  <div class="x-dropdown-menu" [ngClass]="menuClasses" custom-dropdown (hide)="onHide()">
    <ng-content></ng-content>
  </div>
</ng-template>
