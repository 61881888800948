import { Pipe, PipeTransform } from '@angular/core';
import { CustomListColumn } from 'src/app/shared';

@Pipe({
  name: 'isVisibleColumn'
})
export class IsVisibleColumnPipe implements PipeTransform {
  transform(keyword: string, item: CustomListColumn) {
    return !keyword || item?.name?.toLowerCase()?.includes(keyword?.toLowerCase());
  }
}
