<div class="progress-input-group" [ngClass]="{ 'flex-col-reverse': reverse }">
  <div class="percent-group">
    <div class="percent-line">
      <input
        class="range-input"
        type="range"
        [formControl]="control"
        min="0"
        max="100"
        step="1"
        [ngClass]="{ inprogress: (control.value || 0) < 100 }"
        (change)="onProgressChange()"
      />

      <div
        class="percent striped"
        [ngStyle]="getProgressStyle()"
        [ngClass]="{ 'inprogress animated': (control.value || 0) < 100 }"
      ></div>
    </div>
  </div>
  <input
    mask="999"
    suffix="%"
    class="progress-input"
    [formControl]="control"
    (change)="onProgressChange()"
    *ngIf="typing"
  />
</div>
