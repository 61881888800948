import { Group, JobTitleShort, Role, ShiftProfile, Team } from '.';
import { UserGenderValue, UserMaritalValue, UserStatusKey } from '../_utils';
import { BankAccount } from './bank.model';
import { UserProfile } from './user.model';

export interface UserBasicInfo {
  avatar: string;
  avatarLarge: string;
  employeeId: string;
  partTimeFlg: true;
  status: UserStatusKey;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  birthday: string;
  gender: UserGenderValue;
  marital: UserMaritalValue;
  email: string;
  skype: string;
  address: string;
}

export interface UserLocationGroupTeam {
  location: Location,
  jobTitles: JobTitleShort[];
  userGroups: Group[];
  teams: Team[];
  userRoles: Role[];
  shiftProfile: ShiftProfile[];
}

export interface UserJobDates {
  hireDate: string;
  passProbationDate: string;
  contactStart: string;
  contactEnd: string;
  firstWorkDate: string;
}

export interface UserContact {
  phone: string;
  backupPhone: string;
  note: string;
}

export interface UserBankAccount {
  bankAccounts: BankAccount[]
}


export interface UserEditPayload {
  roleCodes?: number[];
  locationId?: number;
  teamIds?: number[];
  jobTitleIds?: number[];
  groupIds?: number[];
  shiftProfileId?: number;
  employeeId?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  status?: UserStatusKey;
  jobTitle?: string;
  avatar?: string;
  avatarLarge?: string;
  banks?: BankAccount[];
  userProfile?: UserProfile;
  partTimeFlg?: boolean;
  syncMultiCompany?: boolean;
}
