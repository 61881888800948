<div
  class="app-avatar-wrapper {{ size }}"
  #avatar
  custom-tooltip
  [templateRef]="tooltipTemplate"
  [viewContainerRef]="appAvatar"
  [tooltipDisabled]="hideTooltip"
  [hideOnMouseLeave]="true"
  [customConfig]="tooltipOverlayConfig"
  panelClass="user-container"
  [ngClass]="!url && name ? 'bg-avt' : 'bg-avt-transparent'"
>
  <img [src]="url" alt="" *ngIf="url; else shortName" />

  <ng-template #tooltipTemplate>
    <div class="m-2 flex items-center">
      <div class="avatar-wrapper" [ngClass]="{ unassigned: !url && name }">
        <img
          class="icon-md rounded-full"
          [src]="url"
          *ngIf="url; else shortName"
        />
      </div>
      <div class="ml-1">{{ name || unassignedTitle }}</div>
    </div>
  </ng-template>

  <ng-template #shortName>
    <span class="name" *ngIf="name">{{ name | shortName }}</span>
    <span class="unassigned-avatar material-icons" *ngIf="!name">
      account_circle
    </span>
  </ng-template>
</div>
