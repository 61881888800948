import { CommonModule } from '@angular/common';
import { 
  Component, 
  ElementRef, 
  EventEmitter, 
  Input, 
  OnChanges, 
  OnDestroy, 
  OnInit, 
  Output, 
  SimpleChanges, 
  ViewChild, 
  ChangeDetectionStrategy, 
  forwardRef 
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR, 
  ReactiveFormsModule, 
  UntypedFormControl 
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { XControlAccessor } from 'src/@xcorp/base';
import { NgxMaskModule } from 'src/app/libs/ngx-mask/ngx-mask.module';

@Component({
  selector: 'x-input',
  standalone: true,
  imports: [CommonModule, NgxMaskModule, ReactiveFormsModule],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent extends XControlAccessor implements OnInit, OnChanges, OnDestroy {
  @Input() type: 'text' | 'number' = 'text';
  @Input() placeholder = '';
  @Input() transparent = false;
  @Input() className = 'form-control';
  @Input() align: 'left' | 'center' | 'right' = 'left';
  @Input() value: string | number = null;
  @Input() disabled = false;
  @Input() focus = false;
  @Input() selectOnFocus = false;
  @Input() isBlurEnter = true;
  @Input() debounce = false;
  @Input() debounceTime = 500;
  @Input() suffix = '';
  @Input() allowNegativeNumbers = true;
  @Input() separator = ',';

  @Output() onSubmit: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() onFocus: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() keywordChange = new EventEmitter<string>();
  @Output() keywordDebounceChange = new EventEmitter<string>();
  @Output() onKeydownEvent = new EventEmitter<KeyboardEvent>();

  @ViewChild('input') input: ElementRef;

  control: UntypedFormControl;
  private inputSub!: Subscription;
  
  // ControlValueAccessor properties
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  constructor() { super(); }

  // ControlValueAccessor implementation
  writeValue(value: any): void {
    if (this.control) {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.control) {
      if (isDisabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  ngOnInit(): void {
    // Create control with initial value
    this.control = new UntypedFormControl(this.value);

    // Focus and select logic
    if (this.focus || this.selectOnFocus) {
      setTimeout(() => {
        if (this.input && this.input.nativeElement) {
          if (this.selectOnFocus) {
            this.input.nativeElement.select();
          } else {
            this.input.nativeElement.focus();
          }
        }
      }, 100);
    }

    // Debounce setup
    if (this.debounce) {
      this.setupDebounce();
    }

    // Initial disabled state
    if (this.disabled) {
      this.control.disable();
    }

    // Add change listener to propagate changes
    this.control.valueChanges.subscribe(value => {
      this.onChange(value);
      this.value = value;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Handle value changes
    if (changes.value && this.control && this.control.value !== changes.value?.currentValue) {
      this.control.setValue(changes.value.currentValue);
    }

    if (changes.disabled && this.control) {
      if (this.disabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  onFocusout() {
    this.submit();
    this.onBlur.emit();
    this.onTouched();
  }

  onKeyupEnter(input?: any) {
    this.submit(input);
    this.onEnter.emit(this.value);
  }

  submit(input?: any) {
    if (this.isBlurEnter) {
      setTimeout(() => {
        input?.blur?.();
      }, 0);
    }

    if (this.control.valid) {
      this.onSubmit.emit(this.value);
    }
  }

  onFocusInput() {
    this.onFocus.emit(this.input);
  }

  onInput() {
    this.keywordChange.emit(this.control.value);
  }

  setupDebounce() {
    this.inputSub = this.keywordChange
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe(() => {
        this.keywordDebounceChange.emit(this.control.value);
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.inputSub?.unsubscribe();
  }

  onkeydown(event: KeyboardEvent) {
    this.onKeydownEvent.emit(event);
  }
}
