import { createFeature, createReducer, on } from '@ngrx/store';
import { TaskActions } from './task.actions';
import { featureKey } from './task.selectors';
import { TaskState } from './task.model';
import { uniqBy } from 'lodash';
import { TaskTypeEnum } from 'src/app/shared';

const initialState: TaskState = {
  taskTypes: [],
  preWorkType: null,
  taskCreated: null,
  taskUpdated: null,
  workCalendarTasks: [],
  taskDeleted: null
};

export const taskReducer = createReducer(
  initialState,
  on(TaskActions.selectWorkItem, (state, { projectId, workItemType }) => {
    const notSavingType = [TaskTypeEnum.Storyline, TaskTypeEnum.TaskTemplate] as string[];
    const preType = notSavingType.includes(workItemType.name)
      ? state.preWorkType?.workItemType
      : workItemType;
    
    return {
      ...state,
      preWorkType: { projectId, workItemType: preType }
    }
  }),
  on(TaskActions.setTaskTypes, (state, { taskTypes }) => ({
    ...state,
    taskTypes
  })),
  on(TaskActions.createTaskSuccess, (state, { task }) => ({
    ...state,
    workCalendarTasks: [...state.workCalendarTasks, task],
    taskCreated: task
  })),
  on(TaskActions.updateTaskSuccess, (state, { task }) => ({
    ...state,
    workCalendarTasks: uniqBy([task, ...state.workCalendarTasks], 'id'),
    taskUpdated: task
  })),
  on(TaskActions.setWorkCalendarTasks, (state, { workCalendarTasks }) => ({
    ...state,
    workCalendarTasks
  })),
  on(TaskActions.deleteWorkCalendarTaskSuccess, (state, { workCalendarTask }) => ({
    ...state,
    workCalendarTasks: state.workCalendarTasks?.filter((task) => task.id !== workCalendarTask.id),
    taskDeleted: workCalendarTask
  })),
);

export const taskFeature = createFeature({
  name: featureKey,
  reducer: taskReducer
});
