import { Injectable, ErrorHandler } from '@angular/core';
import { AppInjector } from 'src/app/app.module';
import { ToastService } from 'src/app/core';
import { defaultErrorMessage } from 'src/app/core/models/http-error';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
  static display(error: any): void {
    const _toast = AppInjector.get(ToastService);

    let errorMessage = defaultErrorMessage;
    if (typeof error === 'string') {
      try {
        const httpError = JSON.parse(error);
        errorMessage = httpError?.message || errorMessage;
      } catch(e) {
        errorMessage = error;
      }
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    _toast.error(errorMessage);
  }
}
