import { isEmpty, isNull, isUndefined } from 'lodash';
import {
  MonoTypeOperatorFunction,
  Observable,
  filter,
  pipe,
  map,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  pairwise,
  startWith
} from 'rxjs';
import { filter as _filter } from 'lodash';
import { ActionFetchProps } from 'src/app/core/models/store';
import { tap } from 'rxjs/operators';

export function nonEmpty<T>(): MonoTypeOperatorFunction<T> {
  return pipe(filter((v) => !(isNull(v) || isUndefined(v))));
}

export function withoutUndefined<T>(): MonoTypeOperatorFunction<T> {
  return pipe(filter((v) => !isUndefined(v)));
}

export function withPropertyIsTrue<T extends any[]>(key: string) {
  return pipe(map((e: T) => _filter(e, (e) => e[key])));
}

export function index<T>(idx: number): MonoTypeOperatorFunction<T> {
  return pipe(map((v) => v[idx]));
}

export function distinctStateChanged<
  T extends [props: ActionFetchProps, state: any]
>() {
  return (observable: Observable<T>) =>
    new Observable<T>((subscriber) => {
      const subscription = observable.subscribe({
        next(res) {
          const [props, state] = res;
          if (props?.force || isEmpty(state)) {
            subscriber.next(res);
          }
        },
        error(err) {
          subscriber.error(err);
        },
        complete() {
          subscriber.complete();
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    });
}

export function scrollStartEnd(element: Element): Observable<{
  status: 'start' | 'end';
  scrollLeft: number;
  scrollTop: number;
}> {
  return new Observable((observer) => {
    let isScrolling = false;

    // Lắng nghe sự kiện cuộn
    const scroll$ = fromEvent(element, 'scroll').pipe(
      // Lấy vị trí cuộn hiện tại
      map(() => ({
        scrollTop: element.scrollTop,
        scrollLeft: element.scrollLeft
      })),
      // Theo dõi vị trí cuộn hiện tại và trước đó
      startWith({ scrollTop: 0, scrollLeft: 0 }),
      pairwise(),
      // Chỉ thay đổi khi có sự khác biệt về vị trí cuộn
      distinctUntilChanged(
        ([prev], [curr]) =>
          prev.scrollTop === curr.scrollTop &&
          prev.scrollLeft === curr.scrollLeft
      )
    );

    // Đăng ký sự kiện cuộn
    const scrollSubscription = scroll$.subscribe(
      ([prevScroll, currentScroll]) => {
        if (!isScrolling) {
          // Khi có sự thay đổi về cuộn và trước đó chưa được cuộn, phát ra giá trị "start"
          observer.next({
            status: 'start',
            scrollLeft: currentScroll.scrollLeft,
            scrollTop: currentScroll.scrollTop
          });
          isScrolling = true; // Đánh dấu là đang cuộn
        }
      }
    );

    // Lắng nghe khi cuộn kết thúc (sau khi không cuộn trong một khoảng thời gian)
    const scrollEndSubscription = scroll$
      .pipe(
        debounceTime(200) // 200ms sau khi không có sự kiện cuộn
      )
      .subscribe(() => {
        if (isScrolling) {
          // Phát ra sự kiện "end" khi cuộn kết thúc
          observer.next({
            status: 'end',
            scrollLeft: element.scrollLeft,
            scrollTop: element.scrollTop
          });
          isScrolling = false; // Đánh dấu là đã dừng cuộn
        }
      });

    // Cleanup khi Observable hoàn thành
    return () => {
      scrollSubscription.unsubscribe();
      scrollEndSubscription.unsubscribe();
    };
  });
}

export function skipNullOrUndefined<T>() {
  return pipe(
    filter(
      (value: T | null | undefined) => value !== undefined && value !== null
    )
  );
}

export function withDebug<T>(label = 'Debugging'): MonoTypeOperatorFunction<T> {
  return pipe(
    tap((value) =>
      console.log(
        `%c[${label}]`,
        'color: lightgreen; font-weight: bold;',
        value
      )
    )
  );
}
