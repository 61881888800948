import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'src/app/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {
  transform(name: string): string {
    return UtilService.getAbbreviationName(name);
  }
}
