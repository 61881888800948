import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'query-string';
import { List2Res, Pageable, TaskContributor, TaskContributorRequest, UserShort } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskContributorService {
  constructor(
    private _http: HttpClient,
  ) { }

  add(taskId: number, payload?: TaskContributorRequest) {
    return this._http.post<TaskContributor>(`${environment.apiUrl}/tasks/${taskId}/contributors`, payload);
  }

  getAll(taskId: number, pageable) {
    const params = stringify({ taskId, ...pageable });
    return this._http.get<List2Res<TaskContributor>>(`${environment.apiUrl}/contributors?${params}`);
  }

  get(contributorId: number) {
    return this._http.get<TaskContributor>(`${environment.apiUrl}/contributors/${contributorId}`);
  }

  update(contributorId: number, payload: TaskContributorRequest) {
    return this._http.put<TaskContributor>(`${environment.apiUrl}/contributors/${contributorId}`, payload);
  }

  delete(contributorId: Number) {
    return this._http.delete<TaskContributor>(`${environment.apiUrl}/contributors/${contributorId}`);
  }

  getAvailableContributors(taskId: number, keyword: string, pageable: Pageable) {
    const params = stringify({ ...pageable, keyword });
    return this._http.get<List2Res<UserShort>>(`${environment.apiUrl}/tasks/${taskId}/available-contributors?${params}`);
  }
}
