export * from './app.reducers';
export * from './app.selectors';
export * from './app.effects';
export * as AppActions from './app.actions';

import { ActionReducerMap } from '@ngrx/store';
import { AppState, appReducer } from './app.reducers';
import { routerReducer, RouterState } from '@ngrx/router-store';

export interface RootState {
  app: AppState;
  router: RouterState;
}

export const appReducers: ActionReducerMap<RootState> = {
  app: appReducer,
  router: routerReducer
};
