import { TimelineLevelType } from 'src/app/site-management/okr/_model';
import { OkrWorkspace } from './okr-workspace.model';

export interface OkrTimeline {
  id: number;
  title: string;
  name: string;
  startDate: string;
  endDate: string;
  deleted: boolean;
  workspace: OkrWorkspace;
}

export interface OkrTimelineCreateRequest {
  title: string;
  startDate: string;
  endDate: string;
  workspaceId: number;
  parentId?: number;
}

export interface OkrTimelineUpdateRequest {
  title: string;
  startDate: string;
  endDate: string;
}

export interface OkrTimelineSearchRequest {
  workspaceId?: number;
  year?: number;
}

export interface OkrTimelinePeriod {
  key: string;
  start: string;
  end: string;
  year: number;
}

export interface OkrTimelineEntity {
  id: number;
  title: string;
  startDate: string;
  endDate: string;
  lastReportDate: string;
  timelineCategory?: TimelineLevelType;

  // FE
  _level?: number;
  _parentId?: number;
  _isParent?: boolean;
  _isCollapse?: boolean;
  _isStartDisable?: boolean;
  _isCurrentDisable?: boolean;
  _isExpectedDisable?: boolean;
  _isVisible?: boolean;
  _tree?: OkrTimelineTree;

  _lft?: number;
  _rgt?: number;
}

export interface OkrTimelineTree {
  entity: OkrTimelineEntity;
  children: OkrTimelineTree[];
}

export const TIMELINE_TREE_MAX_LEVEL = 7;
export const TIMELINE_TREE_FIRST_LEVEL = 1;
