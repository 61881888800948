import { CustomListColumn, SelectOption, TaskConfig } from "../..";
import { ReminderPeriod, StopType, TaskColumnKey } from "../_enums";

export const TASK_DETAIL_SIZE_DEFAULT = 500;
export const TASK_MODAL_CLASS = 'modal-task-detail';

export const taskReminders: SelectOption[] = [
  {
    name: 'In 30 minutes',
    value: ReminderPeriod.MINUTE_30,
  },
  {
    name: 'In 1 hour',
    value: ReminderPeriod.HOUR_1,
  },
  {
    name: 'In 2 hours',
    value: ReminderPeriod.HOUR_2,
  },
  {
    name: 'Next Day',
    value: ReminderPeriod.DAY_1,
  },
  {
    name: 'Next 2 Days',
    value: ReminderPeriod.DAY_2,
  }
];

export const stopTime: SelectOption[] = [
  {
    name: 'Never',
    value: StopType.NEVER,
  },
  {
    name: 'On',
    value: StopType.ON
  },
  {
    name: 'After',
    value: StopType.AFTER
  },
  // {
  //   name: `When task's statuses`,
  //   value: StopType.ON_STATUS
  // }
];

export const DuplicateLinkedTask = 'Task Link Is Duplicated';

export const UNSAVED_CACHES = 'expercUnsavedCaches';
export const UNSAVED_USER_KEY = 'user_';
export const UNSAVED_TASK_KEY = 'task_key_';
export const UNSAVED_COMMENT_KEY = 'comment_id_';
export const UNSAVED_REPLY_COMMENT_KEY = 'reply_comment_id_';

export const TASK_GIT_COMMIT_SORT_PROP = 'createdAt';

export const UNASSIGNED_USER = 'Unassigned';
export const TASK_NAME_MAX_LENGTH_MESSAGE = 'Task names should be limited to 512 characters';
export const COMPLETE_PROGRESS = 100;
export const NAVIGATED_QUERY_KEY = 'expercNavigatedQueryKey';

export const TaskColumns: CustomListColumn[] = [
  { name: 'Task Type', key: TaskColumnKey.TYPE, selected: true },
  { name: 'Task Key', key: TaskColumnKey.KEY, selected: true },
  { name: 'Task Name', key: TaskColumnKey.NAME, selected: true },
  { name: 'Epic', key: TaskColumnKey.EPIC, selected: true },
  { name: 'Assignee', key: TaskColumnKey.ASSIGNEE, selected: true },
  { name: 'Reporter', key: TaskColumnKey.REPORTER, selected: false },
  { name: 'CC', key: TaskColumnKey.CC, selected: false },
  { name: 'Priority', key: TaskColumnKey.PRIORITY, selected: true },
  { name: 'Status', key: TaskColumnKey.STATUS, selected: true },
  { name: 'Story Point', key: TaskColumnKey.STORY_POINT, selected: true },
  { name: 'Due Date', key: TaskColumnKey.DUE_DATE, selected: true },
  { name: 'Platform', key: TaskColumnKey.PLATFORM, selected: true },
  { name: 'Labels', key: TaskColumnKey.LABEL, selected: false },
  { name: 'Releases', key: TaskColumnKey.RELEASE, selected: false },
  { name: 'Sprint', key: TaskColumnKey.SPRINT, selected: false },
  { name: 'Progress', key: TaskColumnKey.PROGRESS, selected: true },
  { name: 'Estimate', key: TaskColumnKey.ESTIMATE, selected: false },
];

export const taskColumnConfig = (columns = TaskColumns) => {
  const config: TaskConfig = {};
  columns.forEach(col => {
    const key = col.key;
    const capitalKey = key.charAt(0).toUpperCase() + key.slice(1);
    config[`visible${capitalKey}`] = col.selected;
  });
  return config;
};

export const updateTaskColumnConfig = (currentConfig: TaskConfig, columns: CustomListColumn[]) => {
  const newConfig = taskColumnConfig(columns);

  Object.keys(currentConfig).forEach(key => {
    currentConfig[key] = newConfig[key];
  });
};
