import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  UserSettingDashboard,
  UserSettingKey,
  UserSettingState
} from './user-setting.model';
import { featureKey } from './user-setting.reducers';

const selectFeature = createFeatureSelector<UserSettingState>(featureKey);

export const selectUserSettingState = (key: UserSettingKey | string) =>
  createSelector(selectFeature, (state: UserSettingState) => state?.[key]);

export const selectUserSettings = createSelector(
  selectFeature,
  (state) => state
);

export const selectOkrDashboard = createSelector(
  selectFeature,
  (state) => state[UserSettingKey.okrDashboard]
);

export const selectOkrDashboardWidgets = createSelector(
  selectFeature,
  (state) => state[UserSettingKey.okrDashboard]?.widgets
);

export const selectDashboardSetting = createSelector(selectFeature, (state) => {
  const setting = state[UserSettingKey.dashboard];

  if (setting) {
    return JSON.parse(setting) as UserSettingDashboard;
  }

  return undefined;
});
