<div class="time-picker-wrapper">
  <input
    #input
    [placeholder]="placeholder | translate"
    class="form-control"
    timePicker
    [control]="control"
    [formControl]="control"
    [defaultValue]="defaultValue"
  />

  <button class="btn btn-clear-date" *ngIf="showClear && control?.value && !control?.disabled" (click)="clearControlValue()">
    <span class="material-icons-outlined h-100 d-block" [title]="'Clear all' | translate">
      close
    </span>
  </button>
</div>
