import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class TimeValidators {
  static time12HourFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const isValidTime = /^(0[1-9]|1[0-2]):[0-5][0-9] ?(AM|PM)$/.test(value);
      return isValidTime ? null : { invalid12TimeFormat: true };
    };
  }
}
