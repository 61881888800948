import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  template: ``
})
export class XControlAccessor implements ControlValueAccessor, OnDestroy {
  @Output() valueChange = new EventEmitter();
  
  destroyed$ = new Subject();

  onChange: (data: any) => void;
  onTouched: () => void;

  writeValue(value: any) {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
