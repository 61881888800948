import { Component, Input, ChangeDetectionStrategy, forwardRef, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { XControlAccessor } from 'src/@xcorp/base';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'x-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgxMaskDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProgressComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressComponent extends XControlAccessor implements OnInit {
  @Input() reverse = false;
  @Input() typing = true;
  control = new FormControl();

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.control.setValue(value > 100 ? 100 : value, { emitEvent: false });
      this.onChange(value);
      this.onTouched();
    });
  }

  writeValue(value: any): void {
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  getProgressStyle() {
    const percent = this.control.value || 0;
    const round = percent <= 33 ? '0.875rem' : '0rem';
    return { width: `calc(${percent}% + ${round})` };
  }

  onProgressChange() {
    this.valueChange.emit();
  }
}
