import { Directive, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[modifierClick]'
})
export class ModifierClickDirective {
  @Input() disableClick: boolean;
  
  @Output() ctrlClick = new EventEmitter<Event>();
  @Output() shiftClick = new EventEmitter<Event>();
  @Output() defaultClick = new EventEmitter<Event>();

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.disableClick) {
      event.preventDefault();
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
      this.ctrlClick.emit(event);
      return;
    }

    if (event.shiftKey) {
      event.preventDefault();
      this.shiftClick.emit(event);
      return;
    }

    event.preventDefault();
    this.defaultClick.emit(event);
  }
}
