import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterItem, FilterObject } from 'src/app/shared/_models';
import { I18nService } from 'src/app/site-management/_services';
import { AbstractComponent } from '../_base-component';

export enum PopoverMode {
  one = 'one',
  two = 'two',
}

@Component({
  selector: 'app-multi-filter',
  templateUrl: './multi-filter.component.html',
  styleUrls: ['./multi-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiFilterComponent extends AbstractComponent {
  @Input() filter: FilterObject = {};
  @Input() loading = false;
  @Input() typeaheadWithKey = false;
  @Input() fullWidth = false;
  @Input() havePeriodFilter = false;
  @Output() filterChange = new EventEmitter<FilterObject>();

  @ViewChild('searchInput') searchInput: ElementRef;
  inputSubject = new Subject<string>();

  popoverMode = PopoverMode;
  currentMode = PopoverMode.one;
  selectedFilter: FilterItem<any>;

  constructor(private i18nService: I18nService,
    private translateService: TranslateService,) {
    super();
  }

  init(): void {
    this.inputSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.onTypeHead(value);
    });
  }

  get searchValue() {
    return this.searchInput?.nativeElement?.value?.toLocaleLowerCase() || '';
  }

  get filters() {
    return Object.values(this.filter);
  }

  get clearable() {
    return this.filters.some(e => e.selecteds.length > 0 && !e.hidden);
  }

  get options(): any[] {
    if (!this.selectedFilter) {
      return [];
    }

    return this.selectedFilter.items
      .filter(e => {
        if (this.selectedFilter.typeahead) {
          return e;
        }

        if (this.selectedFilter.bindLabel) {
          return e[this.selectedFilter.bindLabel]?.toLocaleLowerCase().includes(this.searchValue);
        }

        return e?.toString().toLocaleLowerCase().includes(this.searchValue);
      })
      .filter(e => {
        return this.selectedFilter.selecteds.some(selected => {
          if (this.selectedFilter.bindId) {
            return selected[this.selectedFilter.bindId] === e[this.selectedFilter.bindId];
          }

          return selected === e;
        }) ? false : true;
      });
  }

  onTypeHead(value: string) {
    if (this.selectedFilter?.typeahead) {
      if (this.typeaheadWithKey) {
        this.selectedFilter.typeahead.next({ value, key: this.selectedFilter.key });
      } else {
        this.selectedFilter.typeahead.next(value);
      }
    }
  }

  onInputChange(value: string = '') {
    if (!this.selectedFilter) {
      return;
    }

    this.inputSubject.next(value);
  }

  stopEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  clearSelected(event) {
    this.stopEvent(event);
    if (this.clearable) {
      for (const filter of this.filters) {
        if(!filter.hidden) {
          filter.selecteds.splice(0, filter.selecteds.length);
        }
      }
      
      this.filterChange.emit(this.filter);
    }
  }

  resetSelectedFilter() {
    this.currentMode = PopoverMode.one;
    this.selectedFilter = null;
  }

  changeSelectedFilter(filter: FilterItem<any>) {
    this.currentMode = PopoverMode.two;
    this.selectedFilter = filter;
    this.onTypeHead('');
  }

  selectItem(item: any) {
    if (this.selectedFilter.multiple) {
      this.selectedFilter.selecteds.push(item);
    } else {
      this.selectedFilter.selecteds.splice(0, this.selectedFilter.selecteds.length);
      this.selectedFilter.selecteds.push(item);
    }

    if (this.selectedFilter.radio) {
      for (const filter in this.filter) {
        if (
          this.filter[filter].radio &&
          this.filter[filter].radio === this.selectedFilter.radio &&
          this.selectedFilter.label !== this.filter[filter].label
        ) {
          this.filter[filter].selecteds = [];
        }
      }
    }

    this.filterChange.emit(this.filter);
  }

  removeSelectedItem(filter: FilterItem<any>, index: number) {
    filter.selecteds.splice(index, 1);
    this.filterChange.emit(this.filter);
  }

  getOptionLabel(filter: FilterItem<any>, item: any) {
    if (item?.displayValue) {
      return this.i18nService.getDisplayTextOfMasterData(item);
    } else {
      const key = String(
        filter.bindLabel && item[filter.bindLabel]
          ? item[filter.bindLabel]
          : item
      );
      if (key) {
        return this.translateService.instant(key);
      } else {
        return key;
      }
    }
  }

  getIconLabel(filter: FilterItem<any>, item: any) {
    return filter.bindIcon ? (item[filter.bindIcon] ? item[filter.bindIcon] : "../../../../assets/icons/account_black.svg") : "";
  }
}