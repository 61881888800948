import FroalaEditor from 'froala-editor';
import { FroalaEvent } from '../models';

FroalaEditor.PLUGINS.pasteContentProcessor = function (editor: FroalaEditor) {
  function _init() {
    editor.events.on(FroalaEvent.PASTE_BEFORE_CLEANUP, removeUnnecessaryStyleProps.bind(this));
  }

  function removeUnnecessaryStyleProps (html: string) {
    return html.replace(/\s*!important/gi, '')
               .replace(/<p[^>]*>(<img[^>]*>.*?)<\/p>/gi, '<p>$1</p>');
  }

  return {
    _init
  };
};
